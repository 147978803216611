import { colors } from '@material-ui/core';
//import { mdiTheater } from '@mdi/js';


let mth = {
  root: {
    backgroundColor: colors.grey[50]
  }
};

export default mth;

