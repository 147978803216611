import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
//import ViewData from '@material-ui/icons/FormatAlignJustify';
import {ListAlt as ViewData, Reorder as ViewTable, InsertChart as ViewChart } from '@material-ui/icons';
//import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
//import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import Axios from 'axios';
import { formatDate } from 'config';
import FullScreenDialog from 'components/FullScreenDialog';

import DeviceIcon from 'components/DeviceIcon';
import { Fragment } from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%'
    },
    content: {
        alignItems: 'center',
        display: 'flex'
    },
    title: {
        fontWeight: 700
    },
    avatar: {
        backgroundColor: theme.palette.success.main,
        height: 56,
        width: 56
    },
    icon: {
        height: 32,
        width: 32
    },
    difference: {
        marginTop: theme.spacing(2),
        display: 'flex',
        alignItems: 'center'
    },
    differenceIcon: {
        color: theme.palette.success.dark
    },
    differenceValue: {
        color: theme.palette.success.dark,
        marginRight: theme.spacing(1)
    }
}));

function getTempHumiditySympbol(type) {
    if (typeof type == 'undefined')
        return '';
    if (type.toLowerCase().includes('temperature'))
        return "F";
    else if (type.toLowerCase().includes('humidity'))
        return "%";
    else
        return '';
}

//function showData(data) {
//    alert(data);
//}


const DataCard = props => {
    const { className, ...rest } = props;
    //const [count, setCount] = useState(0);

    const [query] = useState(props.deviceid);
    const [errorMessage, setError] = useState(null);

    const [rowdata, setRowdata] = useState(props.rowdata);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogData, setDialogData] = useState({});
    const [dialogViewType, setDialogViewType] = useState("raw");

    useEffect(() => {

        async function getData() {
           
            Axios.get('/api/Device/' + props.deviceid, {}).then(result => {
                console.log(result.data);
                setRowdata(result.data);
            }).catch(error => {
                console.log('Dashboard Load Areas Error - ', error.message);
                // setRowdata([]);
                setError(error.message);
            })
        };

        // Check to see if rowdata is empty and if Device Id Exists, if so, then get data from db
        if (!props.rowdata)
            //   setRowdata(props.rowdata);
            // else
            getData();
    }, [query, rowdata, props.rowdata, props.deviceid]);

    function handleViewRaw() {
        alert(JSON.stringify(rowdata, null, 3));
    };

    function handleViewData() {
        setDialogData(JSON.stringify(rowdata, null, 3));
        setDialogOpen(true);
        setDialogViewType("table");
    }

    function handleViewChart() {
        setDialogData(JSON.stringify(rowdata, null, 3));
        setDialogOpen(true);
        setDialogViewType("chart");
    }


    const classes = useStyles();

    if (!rowdata)
        return (null);
    else
        return (
            <Fragment>
                <FullScreenDialog dialogOpen={dialogOpen} title={props.rowdata.Name} data={dialogData} dialogViewType={dialogViewType} Close={() => { setDialogOpen(false) }} />

                <Grid
                    item
                    lg={3}
                    sm={6}
                    xl={3}
                    xs={12}
                >
                    <Card
                        {...rest}
                        className={clsx(classes.root, className)}
                    >
                        <CardContent>
                            <Grid
                                container
                                justifyContent="space-between"
                            >
                                <Grid item xs={12}>
                                    <Typography
                                        color="error"
                                        gutterBottom
                                        variant="h3"
                                    >
                                        {errorMessage}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {/* <Avatar className={classes.avatar}> */}
                                    <DeviceIcon rowdata={rowdata} />
                                    {/* </Avatar> */}
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        className={classes.title}
                                        color="textSecondary"
                                        gutterBottom
                                        variant="h3"
                                    >
                                        {rowdata.Description || rowdata.Name}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="h3">
                                        Status: {`${rowdata.Status} ${getTempHumiditySympbol(rowdata.Type)}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container >
                                <Grid item xs={3}>
                                    <Typography
                                        className={classes.differenceValue}
                                        variant="body2"
                                    >
                                        Last Changed: {formatDate(rowdata.updatedAt)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} >
                                    <Tooltip title="View Data">
                                        <IconButton aria-label="viewData" onClick={handleViewRaw}   >
                                            <ViewData />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="View Table">
                                        <IconButton aria-label="viewTable" onClick={handleViewData}   >
                                            <ViewTable />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="View Chart">
                                        <IconButton aria-label="viewChart" onClick={handleViewChart}   >
                                            <ViewChart />
                                        </IconButton>
                                    </Tooltip>


                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid >
            </Fragment >

        );
};

DataCard.propTypes = {
    className: PropTypes.string
};

export default DataCard;
