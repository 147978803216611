import React, { forwardRef } from 'react'
import { ListItem} from '@material-ui/core'
import { NavLink } from 'react-router-dom'
//import { makeStyles } from '@material-ui/core/styles'
//import { colors } from '@material-ui/core';
//import IconExpandLess from '@material-ui/icons/ExpandLess'
//import IconExpandMore from '@material-ui/icons/ExpandMore'

// export interface AppMenuItemComponentProps {
//   className?: string
//   link?: string | null // because the InferProps props allows alows null value
//   onClick?: (event: React.MouseEvent<HTMLElement>) => void
// }

// const useStyles = makeStyles(theme => ({
//     root: {},
//     item: {
//       display: 'flex',
//       paddingTop: 0,
//       paddingBottom: 0
//     },
//     active: {
//         color: theme.palette.primary.main,
//         fontWeight: theme.typography.fontWeightMedium,
//         '& $icon': {
//           color: theme.palette.primary.main
//         }
//     },
//     button: {
//       color: colors.blueGrey[800],
//       padding: '10px 8px',
//       justifyContent: 'flex-start',
//       textTransform: 'none',
//       letterSpacing: 0,
//       width: '100%',
//       fontWeight: theme.typography.fontWeightMedium
//     },
//     icon: {
//       color: theme.palette.icon,
//       width: 24,
//       height: 24,
//       display: 'flex',
//       alignItems: 'center',
//       marginRight: theme.spacing(1)
//     },
//     active: {
//       color: theme.palette.primary.main,
//       fontWeight: theme.typography.fontWeightMedium,
//       '& $icon': {
//         color: theme.palette.primary.main
//       }
//     }
//   }));
  
const CustomRouterLink = forwardRef((props, ref) => (
    <div
      ref={ref}
      style={{ flexGrow: 1 }}
    >
      <NavLink  {...props} />
    </div>
  ));
  
const AppMenuItemComponent = props => {
  const { className, onClick, href, children } = props
    //const classes = useStyles();

  // If link is not set return the orinary ListItem
  if (!href || typeof href !== 'string') {
    return (
    //    <ListItem
    //      button
    //      className={classes}
    //      children={children}
    //      onClick={onClick}
    //      />
      <ListItem
          button
            className={className}
            // disableGutters
            key={props.title}
            children={children}
            onClick={onClick}
      >
        {/* {prop.title} */}
            {/* <Button
                activeClassName={classes.active}
                className={classes.button}
            >
                <div className={classes.icon}><IconExpandMore /></div>
                {props.title}
            </Button> */}
        </ListItem>
    )
  }

  // Return a LitItem with a link component
  return (
    <ListItem
      button
      className={className}
      children={children}
      component={CustomRouterLink}
          to={href}
    >
      {/* <div className={classes.icon}>{props.icon}{props.title}</div> */}
    </ListItem>
      
    // <ListItem
    //       className={classes.item}
    //       disableGutters
    //       key={props.title}
    //       children={children}
    //    >
    //       <Button
    //         activeClassName={classes.active}
    //         className={classes.button}
    //         component={CustomRouterLink}
    //         to={href}
    //       >
    //       <div className={classes.icon}>{props.icon}</div>
    //       {props.title}
    //       </Button>
    // </ListItem>
  )
}

export default AppMenuItemComponent
