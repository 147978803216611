import React from 'react';
import { makeStyles } from '@material-ui/styles';
//import "react-table/react-table.css";
import RemoteDataTable from 'components/RemoteDataTable';

//import config from 'config';
//import { UsersToolbar, UsersTable } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const SingleTableView = (props) => {
  const classes = useStyles();
  
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <RemoteDataTable key={"STV"+props.query.title} query={props.query} options={props.options}  /> 
      </div>
    </div>
  );
};

SingleTableView.defaultProps = {
  options: { filtering: true, pageSize: 10, pageSizeOptions: [10, 20, 30] }
}

export default SingleTableView;
