import React, { Component } from 'react';
import MaterialTable, { MTableToolbar, MTableFilterRow } from '@material-table/core';
import { Typography } from '@material-ui/core';
import Axios from 'axios';
//import RemoteDataTable from '../../components/RemoteDataTable';

//import { formatDate } from 'config';
//import { UsersToolbar, UsersTable } from './components';
import moment from 'moment';

const classes = theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
});

//const formatDate = (rowData, item) => {
//  return moment(rowData[item]).local().format('llll');
//}

class AutomationList extends Component{
  tableRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      data: [],
      columns: this.automationColumns,
      showError: null
    };
  }

  automationColumns = [
    {
      title: 'Device Type',
      field: 'DeviceType',
      hidden: true
    },
    {
      title: '',
      field: 'Description',
      editable: 'never'
    },
    {
      title: 'Device',
      field: 'DeviceId',
      lookup: {}
    },
    {
      title: 'Time On',
      field: 'TimeOn',
      type: 'datetime',
      render: rowData => {
        return <div><div>{typeof rowData.DeviceId === 'undefined' ? 'Sunrise' : ""}</div><div > {moment(rowData.TimeOn).local().format('LT')}</div ></div>
      }
    },
    {
      title: 'Time Off',
      field: 'TimeOff',
      type: 'datetime',
      render: rowData => {
        return <div><div>{typeof rowData.DeviceId === 'undefined' ? 'Sunset' : ""}</div><div > {moment(rowData.TimeOff).local().format('LT')}</div ></div>      }
    },
    {
      title: 'Active',
      field: 'Active',
      type: 'boolean',
      initialEditValue: true,
      lookup: {
        true: 'Active',
        false: 'Disabled'
      }
    }
  ];

  // This ids the Parents of each child
  // Child DeviceId != null
  // Parent: DeviceId == null
  // TimeOn Date of Parent = TimeOn  Date of Child
  // TimeOn of Parent is the SunRise of the date of Parent
  parentChildData = (row, rows) => {
    //return (
    let found = rows.find(parent => {
        //console.log(`Find ${parent.DeviceId} ?? ${row.DeviceId} ::  ${moment(parent.TimeOn).local().format("YYYY-MM-DD")} ?? ${moment(row.TimeOn).local().format("YYYY-MM-DD")}`)
      let v = typeof parent.DeviceId === 'undefined' &&
              typeof row.DeviceId  !== 'undefined' &&
          moment(parent.TimeOn).local().format("YYYY-MM-DD") === moment(row.TimeOn).local().format("YYYY-MM-DD");
        //console.log(`V = ${v}`)  
      return v;
      })
    //)
    //console.log(found)
    return found;
  }

  components = {
    FilterRow: props => <MTableFilterRow {...props} />,
    Toolbar: props => (
      <div>
        <div>
          <MTableToolbar {...props} />
          {this.state.showError == null ? ( // if there is an Error, show Error in ToolBar, otherwise show toolbar
            <div style={{ padding: '10px', display: 'flex' }}>
              {/* <Switch hidden= offLabel={props.title + ": All"} onLabel="On" onChange= {this.handleShowOnlyActive} /> */}
              {/* {this.props.query.ShowOnlyActive ? (
                <Switch
                  key={'ShowOnlyActive' + props.title.replace(/ /g, '_')}
                  id={'ShowOnlyActive' + props.title.replace(/ /g, '_')}
                  offLabel={'Show Only Active'}
                  // offLabel={
                  //   'Show ' +
                  //   props.title +
                  //   ': ' +
                  //   this.props.query.componentOnOffLabels.Off
                  // }
                  // onLabel=""{this.props.query.componentOnOffLabels.On}
                  onLabel=""
                  onChange={this.handleShowOnlyActive}
                  style={{ marginRight: 10 }}
                  checked={this.state.showShowOnlyActive}
                />
              ) : (
                <div> </div>
              )} */}
              {/* {this.props.query.title === 'Lights' ? (
                <Switch
                  id={'ShowAutomatedLights' + props.title}
                  offLabel={'Show Automated Devices'}
                  // offLabel={
                  //   'Show ' +
                  //   props.title +
                  //   ': ' +
                  //   this.props.query.componentOnOffLabels.Off
                  // }
                  // onLabel=""{this.props.query.componentOnOffLabels.On}
                  onLabel=""
                  onChange={this.handleFilterByShowAutomatedChange}
                  style={{ marginRight: 10 }}
                  // If True, show Automated Devices/Lights in List
                  checked={
                    this.props.query.filter && this.props.query.filter.Automated
                  }
                />
              ) : (
                <div> </div>
              )} */}
              {/* <Switch
                id={'ShowSearch' + props.title}
                offLabel={'Show Search:'}
                onLabel=""
                onChange={this.handleShow}
                style={{ marginRight: 10 }}
              />{' '} */}
              {/* <Switch
                id={'ShowFilter' + props.title}
                offLabel={'Show Filter:'}
                onLabel=""
                onChange={this.handleShow}
                style={{ marginRight: 10 }}
              />{' '} */}
            </div>
          ) : (
            <div style={{ padding: '10px', align: 'center' }}>
              <Typography variant="h3" align="center" color="error">
                {' '}
                {this.state.showError}
              </Typography>
            </div>
          )}
        </div>
      </div>
    )
  };

  editable = {
    onRowUpdate: (newData, oldData) => {
      let t = this;
      return new Promise((resolve, reject) => {
        //let DBname = pluralize.singular(t.props.query.title);
        //let deviceId = newData.id || newData._id;
        if (typeof oldData.Description !== 'undefined') {
          t.setState({ showError: `Cannot Edit Day Headers, only single events ` });
          reject();
        } else {

          Axios.put(this.props.config.AutomationList.api, newData)
            .then(function (response) {
              if (response.data.success) {
                //alert("Updating "+ DBname+ " Succeeded "  );
                t.LoadData();
                resolve();
              } else
                throw new Error(response.data.message);
            })
            .catch(function (error) {
              t.setState({ showError: `Failed to Update Automation for ${oldData.Name}  - ${error.response.data.error}` });
              reject();
            });
        }
      });
    },

    onRowDelete: rowData => {
      let t = this;
      return new Promise((resolve, reject) => {
        let t = this;
        let dataArray = [rowData];
        if (typeof rowData.DeviceId === 'undefined') {   // This is a Date row, not data Row
          let rows = this.tableRef.current.dataManager.data.filter(row => typeof row.DeviceId !== 'undefined' && moment(rowData.TimeOn).local().isSame(row.TimeOn, 'day'))
          /// TODO: Change this to server side copy, to can change for Sunrise/Sunset changes
          rows.forEach(row => {
            row.TimeOn = moment(row.TimeOn).add(1, 'days');
            row.TimeOff = moment(row.TimeOff).add(1, 'days');
          })
          dataArray = rows;
        }
        Axios.delete(this.props.config.AutomationList.api, {
          data: dataArray   //send Array of items to delete
        })
          .then(function(response) {
            if (response.data.success) {
              t.LoadData();
              resolve();
            } else
              throw new Error(response.data.message);
          }).catch(function (error) {
            t.setState({ showError: `Failed to Delete Automation for ${rowData.Description || rowData.DeviceId}  - ${error.response.data.error}`});
            reject();
          });
      });
    },
    onRowAdd: newData => {
      let t = this;
      return new Promise((resolve, reject) => {
        Axios.post(this.props.config.AutomationList.api, [newData])
          .then(function(response) {
            if (response.data.success) {
              t.LoadData();
              resolve();
            } else
              throw new Error(response.data.message);
          }).catch(function (error) {
            t.setState({ showError: `Failed to Add Automation for - ${error.response.data.error}`});
            reject();
          });
      });
    }
  };

  actions = [
    {
      icon: 'library_add',
      tooltip: 'Duplicate Automation Item',
      onClick: (event, rowData) => {
        return new Promise((resolve, reject) => {
          let t = this;
          let dataArray = [rowData];
          if (typeof rowData.DeviceId === 'undefined') {   // This is a Date row, not data Row
            let rows = this.tableRef.current.dataManager.data.filter(row => typeof row.DeviceId !== 'undefined' && moment(rowData.TimeOn).local().isSame(row.TimeOn, 'day'))
            /// TODO: Change this to server side copy, to can change for Sunrise/Sunset changes
            rows.forEach(row => {
              row.TimeOn = moment(row.TimeOn).add(1, 'days');
              row.TimeOff = moment(row.TimeOff).add(1, 'days');
            })
            dataArray = rows;
          }
            // Add Copy of current Item to List
          Axios.post(this.props.config.AutomationList.api, dataArray)
            .then(function (response) {
              if (response.data.success) {
                t.LoadData();
                resolve();
              } else
                throw new Error(response.data.message);
            }).catch(function (error) {
              t.setState({ showError: `Failed to Copy Automation Item - ${error.response.data.error}` });
              reject();
            });
        });
      }
    }
  ]

  
  componentDidMount() {
    this.LoadLightList();
    this.LoadData(); 
  }

  LoadData(){
    this.setState({ isLoading: true });
    Axios.get(this.props.config.AutomationList.api, {
      params: {  }
    })
      .then(result => {
        this.setState({ data: result.data.data });
        this.setState({ isLoading: false });
        this.setState({ showError: null });
      })
      .catch(error => {
        //this.setState({ data: ["Error: - "+ error.message], isLoading: false });
        console.log(`Automation  Error - ${error.message}`);
        this.setState({ isLoading: false });
        this.setState({ showError: `Get Automation List ${error.message }` });
      });
  }

  // gets a list of Lights and light IDs to create lookuplist for edit/add
  LoadLightList() {
    Axios.get(this.props.config.RemoteDataTables.DeviceList.Lights.api, {
      params: {}
    })
      .then(result => {
        // get list of Lights, create lookup whichis object of key:name pairs
        let lookup = {};
        result.data.data.forEach(row => lookup[row._id] = row.Name);
        this.automationColumns.forEach(item => {
          if (item.field === 'DeviceId')
            item.lookup = lookup;
        });
        this.setState({ columns: this.automationColumns });
      })
      .catch(error => {
        
        console.log('LoadLightList() Error - ', error.message);
        this.setState({ isLoading: false });
        this.setState({ showError: error.message });
      });
    
  }

  render() {
    return (
      <div className={classes.root}>
        <div className={classes.content}>
          {/* <RemoteDataTable key="SecurityEvents" query={this.props.config.RemoteDataTables.DashSecurity} options={{ filtering: true, pageSize: 10, pageSizeOptions: [10, 20, 30] }} /> */}
          <MaterialTable
            tableRef={this.tableRef}
            //key={'MT' + this.props.query.title.replace(/ /g, '_')}
            title='Automation List'
            size="small"
            style={{ valign: 'top', height: '100%' }}
            options={{ paging: false, actionsColumnIndex: -1 }}
            actions={this.actions}
            columns={this.state.columns}
            editable={this.editable}
            data={this.state.data}
            //onRowClick={this.RowClick}
            components={this.components}
            parentChildData={this.parentChildData}
            isLoading={this.state.isLoading}
            stickyHeader>
          </MaterialTable>
        </div>
      </div>
    );
  }
};

export default AutomationList;
