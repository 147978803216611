import React  from 'react';
import { makeStyles } from '@material-ui/styles';
//import "react-table/react-table.css";
import RemoteDataTable from '../../components/RemoteDataTable';
import config from '../../config';
import {Tabs, Tab} from 'react-materialize';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const TabSelect = function(item){
  localStorage.setItem("DeviceList.Tabs.Index", this.title);
  console.log("Tab Index = "+ this.title)
}

// const checkActive = function(title, startIndex){
//   return title == startIndex;
// }

//class DeviceList extends Component{
const DeviceList = () => {
//  render(){
  const classes = useStyles();

  let startIndex = localStorage.getItem("DeviceList.Tabs.Index") || 0;
  console.log("Start Index = "+ startIndex);
  
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Tabs options={{swipeable: false, onShow:TabSelect}} index={startIndex} >
          <Tab  options={{duration: 300, onShow: null, swipeable: true}} title= "Zones">
            <RemoteDataTable query={config.RemoteDataTables.DeviceList.Zones} options={{paging: false, actionsColumnIndex: -1}}  /> 
          </Tab>
          <Tab  options={{duration: 300, onShow: null, swipeable: true}} title= "Lights">
            <RemoteDataTable query={config.RemoteDataTables.DeviceList.Lights} options={{paging: false, actionsColumnIndex: -1}}  /> 
          </Tab>
          <Tab  options={{duration: 300, onShow: null, swipeable: true}} title= "Flags">
            <RemoteDataTable query={config.RemoteDataTables.DeviceList.Flags} options={{paging: false, actionsColumnIndex: -1}}  /> 
          </Tab>
          <Tab  options={{duration: 300, onShow: null, swipeable: true}} title= "Outputs">
            <RemoteDataTable query={config.RemoteDataTables.DeviceList.Outputs} options={{paging: false, actionsColumnIndex: -1}}  /> 
          </Tab>
          <Tab  options={{duration: 300, onShow: null, swipeable: true, actionsColumnIndex: -1}} title= "Cameras">
            <RemoteDataTable query={config.RemoteDataTables.DeviceList.Cameras} options={{paging: false}}  /> 
          </Tab>
          <Tab  options={{duration: 300, onShow: null, swipeable: true, actionsColumnIndex: -1}} title= "Sensors">
          <RemoteDataTable query={config.RemoteDataTables.DeviceList.Sensors  } options={{paging: false}}  /> 
          </Tab>
          <Tab  options={{duration: 300, onShow: null, swipeable: true, actionsColumnIndex: -1}} title= "NetworkDevices">
            <RemoteDataTable query={config.RemoteDataTables.DeviceList.NetworkDevices  } options={{paging: false}}  /> 
          </Tab>
          <Tab  options={{duration: 300, onShow: null, swipeable: true, actionsColumnIndex: -1}} title= "Buttons">
          <RemoteDataTable query={config.RemoteDataTables.DeviceList.Buttons  } options={{paging: false}}  /> 
          </Tab>
          <Tab  options={{duration: 300, onShow: null, swipeable: true, actionsColumnIndex: -1}} title= "Messages">
          <RemoteDataTable query={config.RemoteDataTables.DeviceList.Messages  } options={{paging: false}}  /> 
          </Tab>
          <Tab  options={{duration: 300, onShow: null, swipeable: true, actionsColumnIndex: -1}} title= "SecuritySettings">
            Security Settings
          </Tab> 
        </Tabs>
      </div>
    </div>
  );
//  }
};

export default DeviceList;
