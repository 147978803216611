import React, { useEffect, useState, Fragment } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Dialog, AppBar, Toolbar, IconButton, Typography, CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import RefreshIcon from '@material-ui/icons/Refresh';

import config from '../config';
import RemoteDataTable from './RemoteDataTable';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine
} from 'recharts'

import Axios from 'axios';
//import { element } from 'prop-types';
//import { color } from 'd3';
//import { data } from 'views/Dashboard/components/LatestSales/chart';

//import { TextField } from '@material-ui/core';

//const colorList = ["red", "green", "blue", "maroon", "indigo", "lime", "brown", "yellow", "olive", "teal", "grey", "salmon", "palegreen", "aqua"];

function getUniqueColor(n) {
    // Don't use Black 
    n++;
    const rgb = [0, 0, 0];

    for (let i = 0; i < 24; i++) {
        rgb[i % 3] <<= 1;
        rgb[i % 3] |= n & 0x01;
        n >>= 1;
    }

    return '#' + rgb.reduce((a, c) => (c > 0x0f ? c.toString(16) : '0' + c.toString(16)) + a, '');
}
const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        color: 'inherit',
        fontSize: '2vw'
    },
    buttons: {
        fontSize: '1vw',
        color:  'inherit'
    },
    textField: {
        fontSize: '1vw',
        color: 'inherit',
        textAlign: 'center'
    },
}));


export default function FullScreenDialog(props) {
    const classes = useStyles();
    const [displayDate, setDisplayDate] = useState(moment());
    const [refreshData, setRefreshData] = useState(false);  // This is used to reset the data when the refersh button is pushed
    //const [dialogOpen, setOpen] = React.useState(props.dialogOpen);

    // const handleClickOpen = () => {
    //   setOpen(true);
    // };

    const handleClose = () => {
        // Use a callback to calling function to close to set the props.dialogOpen to close
        // the dialog
        props.Close();
    };

    const handleDateChange = (newValue) => {
        if (newValue.isBefore(moment().endOf("day")))
            setDisplayDate(newValue);

    }

    const handlePreviousDate = (event) => {
        let day = moment(displayDate);
        day.subtract(1, "days");
        handleDateChange(day);
    }
    const handleNextDate = (event) => {
        let day = moment(displayDate);
        day.add(1, "days");
        handleDateChange(day);
    }

    return (
        <div>
            {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open full-screen dialog
      </Button> */}
            <Dialog fullScreen open={props.dialogOpen} onClose={handleClose} >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h2" className={classes.title}>
                        {/* <h1 style="font-size:8vw;"> */}
                        {/* <h1 style={{fontSize:"2vw"}}> */}
                            {props.title} - {displayDate.format("dddd LL")}
                        {/* </h1> */}
                        </Typography>
                        <IconButton
                            color="inherit"
                            tooltip="Refresh Data"
                            onClick={() => setRefreshData(!refreshData)}
                        >
                            <RefreshIcon />
                        </IconButton>


                        <Button autoFocus color="inherit" onClick={handlePreviousDate} className={classes.buttons}>
                            Previous Date
                        </Button>
                        <MuiPickersUtilsProvider utils={MomentUtils} >
                            <DatePicker
                                className={classes.textField}
                                disableFuture
                                //label="Display Date"
                                format="MM/DD/YYYY"
                                value={displayDate}
                                onChange={handleDateChange}
                                inputProps={{style: { textAlign: 'center', fontSize: '1vw' }}}
                                InputProps={{ className: classes.textField }}
                            />
                        </MuiPickersUtilsProvider>
                        {/* <TextField label="Display Date" type="date" defaultValue={displayDate} onChange={handleDateChange} /> */}
                        <Button autoFocus onClick={handleNextDate} className={classes.buttons}>
                            Next Date
                        </Button>
                        <Button autoFocus onClick={handleClose} className={classes.buttons}>
                            Close
                        </Button>
                    </Toolbar>
                </AppBar>

                <GetDialogData displayDate={displayDate} {...props} />

            </Dialog>
        </div>
    );
}

//This sets up the Y Axis.  Either setting one Y axis or two
function ChartYAxis(chartLines) {
    if (typeof chartLines === "undefined" || chartLines === null || chartLines.length === 1) {  // Single Line
        return <YAxis yAxisId="left" allowDecimals={false} domain={[0, 'dataMax + 1']} />
    } else if (chartLines.findIndex(i => i.max === 1) !== -1) {  // multiple lines, one of which only has O & 1 as values
        return (
            <Fragment>
                <YAxis dataKey="status" yAxisId="left" allowDecimals={false} domain={[0, 'dataMax + 10']} />
                <YAxis dataKey="status" yAxisId="right" allowDecimals={false} domain={[0, 'dataMax + 1']} orientation="right" />
            </Fragment>
        )
    } else // multiple lines, but none are O/1 values
        return <YAxis yAxisId="left" allowDecimals={false} domain={[0, 'dataMax + 10']} />

}


function GetDialogData(props) {
    const [query, setQuery] = useState({});
    const [chartData, setChartData] = useState([]);
    const [drawLineChart, setDrawLineChart] = useState(false);
    const [drawBarChart, setDrawBarChart] = useState(false);
    const [xDomain, setXDomain] = useState([]);
    const [xTicks, setXTicks] = useState([]);
    const [isLoadingCopyDB, setIsLoadingCopyDb] = useState(false);
    const [yAxis, setYAxis] = useState({
        domain: [],
        ticks: []
    });



    //const [chartLines, setChartLines] = useState([]);

    const classes = useStyles();


    // Compare function to sort Data.  This Compares the "Name.Type" string, then date, so that for multi-device data
    // The List of data on chart are in sort order.
    const compare = function (a, b) {
        if (`${a.Name}.${a.Type}` < `${b.Name}.${b.Type}`)
            return -1;
        if (`${a.Name}.${a.Type}` > `${b.Name}.${b.Type}`)
            return 1;

        if (moment(a.Date).isBefore())
            return -1;
        if (moment(a.Date).isAfter(b.Date))
            return 1;

        return 0;
    }

    useEffect(() => {
        if (!props.dialogOpen)
            return;
        
        // Set the Query and other state
        let q = config.RemoteDataTables.DashSecurity;
        q.filter.multipleDays = false;

        q.filter.date = props.displayDate.format('YYYY-MM-DD');
        q.filter.multipleDays = false;

        let rowData = JSON.parse(props.data);

        if (typeof rowData.Devices !== "undefined" && Array.isArray(rowData.Devices) && rowData.Devices.length > 0)
            q.filter.DeviceId = rowData.Devices;
        else if (typeof rowData.DeviceId !== "undefined")
            q.filter.DeviceId = rowData.DeviceId;
        else {
            alert("No DeviceID or Device List in RowData, cannot show Graph or Chart");
            return;
        }

        setQuery(q);
        let ticks = [];
        let day = moment(q.filter.date).local().startOf('day');
        for (let i = 0; i < 25; i++) {
            ticks.push(moment(day.startOf('day')).add(i, 'h').toDate());
        }
        setXTicks(ticks);

        setXDomain([day.startOf('d').toDate(), day.add(1, 'd').startOf('d').toDate()]);

        // Only Get Data from server if this is a chart, raw has all data it needs, Table gets data in the table element
        console.log(`dialogViewType = ${props.dialogViewType.toLowerCase()}`);
        if (props.dialogViewType.toLowerCase() !== 'chart')
            return;
        setIsLoadingCopyDb(true);
        console.log(`DialogData ${q.api}`);
        Axios.get(q.api, { params: { data: q.filter } }).then(result => {

            let data = result.data.data;
            if (Array.isArray(data) && data.length > 0) {
                let cd = [];
                // cd = data.map(obj => {
                //   let rObj = {};
                //   rObj.Date = moment(obj.Date).toDate();
                //   if (typeof obj.StatusNum === "undefined" || obj.StatusNum === null)
                //     rObj[obj.Type] = obj.Status;
                //   else
                //     rObj[obj.Type] = obj.StatusNum
                //   return rObj;
                // });
                /// Sort Array of elements into oldest to newest.
                //data.reverse();
                data.sort(compare);
                let cdObj = {};
                data.forEach(obj => {
                    let dataSetName = `${obj.Name}.${obj.Type}`;
                    if (typeof cdObj[dataSetName] === "undefined")
                        cdObj[dataSetName] = { name: dataSetName, data: [], min: 0, max: 0 };
                    let status = obj.Status;
                    if (typeof obj.StatusNum !== "undefined" && obj.StatusNum !== null) {
                        status = obj.StatusNum
                        cdObj[dataSetName].min = (status < cdObj[dataSetName].min) ? status : cdObj[dataSetName].min;
                        cdObj[dataSetName].max = (status > cdObj[dataSetName].max) ? status : cdObj[dataSetName].max;
                    }
                    cdObj[dataSetName].data.push({ Date: moment(obj.Date).toDate(), status: status })
                });

                // Convert from object of objects to array of objects, with each object being a series of date (ie line ) in the chart
                Object.keys(cdObj).forEach(key => {
                    // Add entry with time of start of day to each array of data.  For max=0, add a element with 0, for max =1, add value 0, for 
                    // max > 1, add value at midnight equal to first value.
                    let firstData = cdObj[key].data[0];
                    if (typeof cdObj[key].max !== "undefined" && (cdObj[key].max === 0 || cdObj[key].max === 1))
                        cdObj[key].data.unshift({ Date: moment(firstData.Date).startOf('d').toDate(), status: 0 });
                    else
                        cdObj[key].data.unshift({ Date: moment(firstData.Date).startOf('d').toDate(), status: firstData.status });
                    // if it is today, add data to end of data that has current time and last data value, this shows current value at current time
                    if (moment().local().isSame(q.filter.date, "date")) {
                        let last = cdObj[key].data[0];
                        // Find Last item by Date
                        cdObj[key].data.forEach(obj => {
                            last = moment(obj.Date).isAfter(last.Date) ? obj : last
                        });
                        last = Object.assign({}, last);   // Clone Last
                        last.Date = moment().local().toDate();
                        cdObj[key].data.push(last);
                    }
                    cd.push(cdObj[key]);
                });


                setChartData(cd);
                // Find the largest value in all the data, this sets the top value (aka domain) for the chart
                // after finding, round to next 10 up
                let domainMax = 0, domainMin = typeof cd[0] !== "undefined" ? cd[0].min : 0;
                domainMax = cd.reduce((value, element) => value < element.max ? Math.round(element.max / 10) * 10 : value, 0);
                domainMin = cd.reduce((value, element) => value > element.max ? Math.round(element.max / 10) * 10 : value, 0);

                let Ticks = [];
                for (let i = domainMin; i <= domainMax; i += 10)
                    Ticks.push(i);
                setYAxis({
                    domain: [domainMin, domainMax],
                    ticks: Ticks
                })
                setDrawBarChart(cd.reduce((value, element) => value || (element.max <= 1), false));

                setDrawLineChart(cd.reduce((value, element) => value || (element.max > 1), false));
            } else
                setChartData([]);

        }).catch(error => {
            console.log(`FullScreenDialog() Fetch Data Failed - ${error.message}`);
            alert(`FullScreenDialog() Fetch Data Failed - ${error.message}`);
        }).finally(() => {
            setIsLoadingCopyDb(false);
        });

    }, [props]);

    

    switch (props.dialogViewType.toLowerCase()) {
        case 'raw':
            return <div><pre>{props.data}</pre></div>
        case 'table':
            if (JSON.stringify(query) === '{}')
                return (<div><h1>Error, the query is Empty</h1></div>)
            
            // Not re-rendering when query changes, as it an object.  Added the key=string and started re-rendering
            return (
                <div className={classes.root}>
                    <Grid container spacing={3} className={classes.content}>
                        <Grid item xs={12}>
                            <RemoteDataTable key="SecurityEvents" key={JSON.stringify(query)} query={query} options={{ filtering: true, pageSize: 10, pageSizeOptions: [5, 10, 20, 30] }} />
                            
                        </Grid>
                    </Grid>
                </div>
            )
        case 'chart':

            // This makes a list of the different XAxis items to chart, by getting the
            // Type (which is he key of each recoord) and adding it to a unique array.  Igrore
            // the "Date" key of theObject
            const handleLineChartFullScreen = () => {
                if (!drawBarChart) {
                    let d = chartData.reduce((value, element) => value || (element.max <= 1), false);
                    setDrawBarChart(d);
                } else
                    setDrawBarChart(false);
            }

            const handleBarChartFullScreen = () => {
                if (!drawLineChart) {
                    let d = chartData.reduce((value, element) => value || (element.max > 1), false);
                    setDrawLineChart(d);
                } else
                    setDrawLineChart(false);
            }

            return (
                <Fragment>
                    {isLoadingCopyDB && <Grid container width="100%" height="100%" align="center" justifyContent="center"><Grid item height="100%"><CircularProgress color="primary"  /></Grid></Grid>}
                    {!isLoadingCopyDB && !drawLineChart && !drawBarChart && <div><h2>No Data from Server for this Date</h2></div>}
                    {!isLoadingCopyDB && drawLineChart && <ResponsiveContainer key="main" width="100%" height={drawBarChart ? "50%" : "100%"}>
                        {/* <LineChart data={chartData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}> */}
                        <LineChart
                            syncId="charts"
                            margin={{ top: 5, right: 20, left: 20, bottom: 5 }}
                            onClick={handleLineChartFullScreen}
                        >
                            <CartesianGrid strokeDasharray="3 3" />

                            <XAxis
                                dataKey="Date"
                                type="category"
                                allowDuplicatedCategory={false}
                                tickFormatter={(t) => moment(t).format('hA')}
                                //domain={[day.startOf().toDate(), day.endOf().toDate()]} 
                                domain={xDomain}
                                ticks={xTicks}
                                // tickCount={25}
                                scale={'time'}
                            />
                            {/* {ChartYAxis(chartData)} */}
                            {/* <YAxis yAxisId="right" orientation="right" allowDecimals={false} domain={[0, 2]} /> */}
                            <YAxis
                                dataKey="status"
                                yAxisId="left"
                                allowDecimals={false}
                                ticks={yAxis.ticks}
                                domain={yAxis.domain} />
                            <Tooltip />
                            <Legend />
                            {// Add only the lines to the chart where the max value is greater than 1 (show all others in bar charts)
                            }
                            {chartData.map((element, index) => (
                                element.max > 1 && <Line
                                    key={element.name}
                                    data={element.data}
                                    name={element.name}
                                    dot={false}
                                    type="stepAfter"
                                    dataKey="status"
                                    stroke={getUniqueColor(index)}
                                    yAxisId={element.max === 1 ? "right" : "left"}
                                />
                            ))}
                        </LineChart>
                    </ResponsiveContainer>
                    }

                    {!isLoadingCopyDB && drawBarChart && <BinaryBarChart
                        key={`BBC`}
                        data={chartData}
                        height={drawLineChart ? "50%" : "100%"}
                        syncId={"charts"}
                        onClick={handleBarChartFullScreen}
                        domain={xDomain}
                        ticks={xTicks}
                        barDataKey={"status"} />}

                </Fragment >
            );

        default:
            return (<div><pre>No Data Returned</pre></div>)

    }
}

function BinaryBarChart(props) {
    let stackCount = 0;
    let refCount = 0;
    let yCount = 0;
    Object.keys(props.data).forEach(element => {
        if (props.data[element].max <= 1) yCount++;
    });
    //let yDomain = [0, yCount * 2];
    //let yTicks = [];

    return (
        <ResponsiveContainer key={`ResponsiveContainer`} width="100%" height={props.height}>
            <LineChart
                margin={{ top: 5, right: 20, left: 20, bottom: 5 }}
                syncId="charts"
                onClick={props.onClick}
            // data={props.element.data}
            >
                <CartesianGrid strokeDasharray="3 3" horizontal={false} />
                <XAxis
                    dataKey="Date"
                    type="category"
                    allowDuplicatedCategory={false}
                    tickFormatter={(t) => moment(t).format('hA')}
                    domain={props.domain}
                    ticks={props.ticks}
                    scale="time"
                />
                <YAxis
                    type="number"
                    allowDuplicatedCategory={false}
                    allowDecimals={false}
                    domain={[0, 2 * { yCount }]}
                    tickFormatter={(t) => t % 2 ? "On" : "Off"}
                    tickCount={yCount * 2}

                //ticks={["Off", "On", ""]}
                />
                <Tooltip />
                <Legend />
                {props.data.map((element, index) => {
                    if (element.max <= 1) {
                        let y = refCount;
                        refCount++;
                        return (<ReferenceLine key={"Ref" + element.name} y={y * 2} stroke="black" />);
                    }
                })}

                {props.data.map((element, index) => {
                    if (element.max <= 1) {
                        //let data = element.data.map(d => ({ Date: d.Date, status: d.status ? d.status * stackCount : d.status }));
                        let data = element.data.map(d => ({ Date: d.Date, status: d.status + stackCount * 2 }));
                        stackCount++;
                        return (
                            <Line
                                data={data}
                                stackId="1"
                                type="stepAfter"
                                key={"Line" + element.name}
                                name={element.name}
                                dataKey={props.barDataKey}
                                fill={getUniqueColor(index)}
                                stroke={getUniqueColor(index)}
                                connectNulls
                                dot={false}
                            />
                        )
                    }
                    // return (element.max <= 1 && (
                    //     <Area
                    //         data={element.data}
                    //         stackId="1"
                    //         type="stepAfter"
                    //         key={element.name}
                    //         name={element.name}
                    //         dataKey={props.barDataKey}
                    //         fill={getUniqueColor(index)}
                    //     />
                    // ));
                })
                }
            </LineChart>
        </ResponsiveContainer >
    );
}