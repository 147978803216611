import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, createStyles } from '@material-ui/core/styles'
// import { SvgIconProps } from '@material-ui/core/SvgIcon'

import { List, colors } from '@material-ui/core';

import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Collapse from '@material-ui/core/Collapse'

import IconExpandLess from '@material-ui/icons/ExpandLess'
import IconExpandMore from '@material-ui/icons/ExpandMore'

import AppMenuItemComponent from './AppMenuItemComponent'

// React runtime PropTypes
export const AppMenuItemPropTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
  icon: PropTypes.elementType,
  items: PropTypes.array,
}

// TypeScript compile-time props type, infered from propTypes
// https://dev.to/busypeoples/notes-on-typescript-inferring-react-proptypes-1g88
// type AppMenuItemPropTypes = PropTypes.InferProps<typeof AppMenuItemPropTypes>
// type AppMenuItemPropsWithoutItems = Omit<AppMenuItemPropTypes, 'items'>

// Improve child items declaration
// export type AppMenuItemProps = AppMenuItemPropsWithoutItems & {
//   items?: AppMenuItemProps[]
// }

const useStyles = makeStyles(theme => 
    createStyles({
        menuItem: {
          '&.active': {
            background: 'rgba(0, 0, 0, 0.08)',
            '& .MuiListItemIcon-root': {
              color: '#fff',
            },
          },
        },
        menuItemIcon: {
          color: '#97c05c',
        },
        root: {},
        item: {
            display: 'flex',
            paddingTop: 0,
            paddingBottom: 0
        },
    button: {
        color: colors.blueGrey[800],
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme.typography.fontWeightMedium
    },
    icon: {
      color: theme.palette.icon,
      width: 24,
      height: 24,
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(1)
    },
    active: {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
      '& $icon': {
        color: theme.palette.primary.main
      }
    }
  }));
  

const AppMenuItem = props => {
    const { items = [] } = props;
    
    const isExpandable = items && items.length > 0
    const [open, setOpen] = React.useState(false)

    function handleClick() {
        setOpen(!open)
    }

  const classes = useStyles();
  
  // function checkIsExpandble() {
  //   if (isExpandable && !open)
  //     return <div>Open</div>
  //   else if (isExpandable && open)
  //     return <div>Close</div>;
  //   else 
  //     return <div>NO</div>
  // }
    
    const MenuItemRoot = (
        <AppMenuItemComponent className={classes.menuItem} onClick={handleClick} {...props}>
            {/* Display an icon if any */}
            {!!props.icon && (
                <ListItemIcon className={classes.icon}>
            {props.icon}
                </ListItemIcon>
            )}
            <ListItemText primary={props.title} inset={!props.icon} />
            {/* Display the expand menu if the item has children */}
        {isExpandable && !open && <IconExpandMore style={{ color: "grey" }} />}
            {isExpandable && open && <IconExpandLess style={{ color: "grey" }}/>  }
        

        </AppMenuItemComponent>
    )

    const MenuItemChildren = isExpandable ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
            <Divider />
            <List component="div" disablePadding>
                {items.map((item, index) => (
                    <AppMenuItem {...item} key={index} />
                ))}
            </List>
            <Divider />
        </Collapse>
        
  ) : null

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  )
}



export default AppMenuItem
