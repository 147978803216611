/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
//import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List, colors } from '@material-ui/core';
import AppMenuItem from './AppMenuItem';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

// const CustomRouterLink = forwardRef((props, ref) => (
//   <div
//     ref={ref}
//     style={{ flexGrow: 1 }}
//   >
//     <RouterLink {...props} />
//   </div>
// ));

const SidebarNav = props => {
  const { appMenuItems, className } = props;

  const classes = useStyles();

  return (
    <List component="nav"  className={clsx(classes.root, className)}>
      {/* <AppMenuItem {...appMenuItems[0]} /> */}
      {appMenuItems.map((item, index) => (
        <AppMenuItem {...item} key={index} />
      ))}
    </List>

    // <List
    //   {...rest}
    //   className={clsx(classes.root, className)}
    // >
    //   {pages.map(page => (
    //     <ListItem
    //       className={classes.item}
    //       disableGutters
    //       key={page.title}
    //     >
    //       <Button
    //         activeClassName={classes.active}
    //         className={classes.button}
    //         component={CustomRouterLink}
    //         to={page.href}
    //       >
    //         <div className={classes.icon}>{page.icon}</div>
    //         {page.title}
    //       </Button>
    //     </ListItem>
    //   ))}
    // </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  appMenuItems: PropTypes.array.isRequired
};

export default SidebarNav;
