import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import IconFlag from '@material-ui/icons/Flag';
import IconPower from '@material-ui/icons/Power';
import IconBulb from '@material-ui/icons/WbIncandescent';
//import IconMotion from '@material-ui/icons/DirectionsRun';
import IconTemperature from '@material-ui/icons/AcUnit';
import VideocamIcon from '@material-ui/icons/Videocam';
import NetworkWifiIcon from '@material-ui/icons/NetworkWifi';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import ListIcon from '@material-ui/icons/List';
import IconLock from '@material-ui/icons/Lock';
import MdiIcon from '@mdi/react';
import { mdiThermometerLines } from '@mdi/js';

//import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import TextFieldsIcon from '@material-ui/icons/TextFields';
//import ImageIcon from '@material-ui/icons/Image';
//import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SettingsIcon from '@material-ui/icons/Settings';
//import LockOpenIcon from '@material-ui/icons/LockOpen';
import RoomIcon from '@material-ui/icons/MeetingRoom';

import config from 'config';

import { Profile, SidebarNav } from './components';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const pages = [
    {
      title: 'Dashboard',
      href: '/dashboard',
      icon: <DashboardIcon />
    },
    {
      title: 'Devices',
      icon: <TextFieldsIcon />,
      items: [
        {
          title: 'Rooms',
          href: '/device.rooms',
          icon: <RoomIcon />
        },
        {
          title: 'Thermostats',
          href: 'device.thermostats',
          icon: <MdiIcon  path={mdiThermometerLines} size={1} ></MdiIcon>
        },
        {
          title: 'Areas',
          href: '/device.areas',
          icon: <IconLock />
        },
        {
          title: 'Zones',
          href: '/device.zones',
          icon: <IconLock />
        },
        {
          title: 'Lights',
          href: '/device.lights',
          icon: <IconBulb />
        },
        {
          title: 'Flags',
          href: '/device.flags',
          icon: <IconFlag />
        },
        {
          title: 'Outputs',
          href: '/device.outputs',
          icon: <IconPower />
        },
        {
          title: 'Cameras',
          href: '/device.cameras',
          icon: <VideocamIcon />
        },
        {
          title: 'Sensors',
          href: '/device.sensors',
          icon: <IconTemperature />
        },
        {
          title: 'Network Devices',
          href: '/device.networkdevices',
          icon: <NetworkWifiIcon />
        },
        {
          title: 'Buttons',
          href: '/device.buttons',
          icon: <RadioButtonCheckedIcon />
        },
        {
          title: 'Messages',
          href: '/device.messages',
          icon: <ListIcon />
        },
        {
          title: 'Sewer Pump History',
          href: '/device.SewerPump',
          icon: <ListIcon />
        }
      ]
    },
    {
      title: 'Logs',
      icon: <ListIcon />,
      items: config.logs.map((log) => {
                return {
                  title: log.title,
                  href: '/logs/' + log.log,
                  icon: <ListIcon />
                }
              })
    },
    {
      title: 'Automation List',
      href: '/automationlist',
      icon: <IconBulb />
    },

    {
      title: 'Security Events',
      href: '/securityevents',
      icon: <IconLock />
    },
    {
      title: 'Users',
      href: '/users',
      icon: <PeopleIcon />
    },
    // {
    //   title: 'Products',
    //   href: '/products',
    //   icon: <ShoppingBasketIcon />
    // },
    // {
    //   title: 'Authentication',
    //   href: '/sign-in',
    //   icon: <LockOpenIcon />
    // },
    // {
    //   title: 'Icons',
    //   href: '/icons',
    //   icon: <ImageIcon />
    // },
    // {
    //   title: 'Account',
    //   href: '/account',
    //   icon: <AccountBoxIcon />
    // },
    {
      title: 'Settings',
      href: '/settings',
      icon: <SettingsIcon />
    },
    // {
    //   title: 'Typography',
    //   href: '/typography'
    // }
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}>
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} appMenuItems={pages} />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
