import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch, BrowserRouter } from "react-router-dom";


import * as serviceWorker from './serviceWorker';
import App from './App';

//ReactDOM.render(<App />, document.getElementById('root'));
ReactDOM.render(
    <BrowserRouter>
     <Switch>
      <Route path="/" component={App} />
     </Switch>
    </BrowserRouter>,
    document.getElementById("root")
   );
serviceWorker.unregister();
