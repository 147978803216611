import React, { Fragment } from 'react';
//import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
//import config from 'config'
import RemoteDataTable from 'components/RemoteDataTable';
import Axios from 'axios';

import { DataCard } from './components';
//import { setConstantValue } from 'typescript';

//const deviceList = ["5ccb72ae4799ac2254b57505", "5ccb73194799ac2254b57538"];

// const useStyles = makeStyles(theme => ({
//   root: {
//     padding: theme.spacing(4)
//   }
// }));

//const Dashboard = () => {
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      areas: [],
      cards: []
    };

    //const [query, setQuery] = useState(config.RemoteDataTables.DashDevices.Areas.api);
    //const [areas, setAreas] = useState([]);

    //const [deviceList, setDeviceList] = useState([]);
  }

  componentDidMount() {
    Axios.get(this.props.dashdevices.Areas.api, {})
      .then(result => {
        this.setState({ areas: result.data.data });
      })
      .catch(error => {
        console.log('Dashboard Load Areas Error - ', error.message);
        this.setState({ areas: [] });
      });

    Axios.get(this.props.dashdevices.DashCards.api)
      .then(result => {
        this.setState({ cards: result.data.data });
      })
      .catch(error => {
        console.log('Dashboard Load CardList Error - ', error.message);
        this.setState({ cards: [] });
      });
  }

  render() {
    //const classes = useStyles();
    return (
      //<div className={classes.root}>
      <div>
        <Grid container spacing={4}>
          {this.state.areas.map(area => (
            <Fragment key={'key' + area.Name}>
              <DataCard rowdata={area} />
            </Fragment>
          ))}

          {this.state.cards.map(device => (
            <Fragment key={'key' + device.Name}>
              <DataCard rowdata={device} />
            </Fragment>
          ))}
          <Grid item lg={8} md={12} xl={6} xs={12}>
            <RemoteDataTable
              key={'DashBoard' + this.props.dashdevices.Zones.title.replace(/ /g, '_')}
              query={this.props.dashdevices.Zones}
              options={{
                filtering: false,
                paging: false,
                showShowOnlyActive: true,
                actionsColumnIndex: -1
              }}
            />
          </Grid>
          <Grid item lg={8} md={12} xl={6} xs={12}>
            <RemoteDataTable
              key={'DashBoard'+this.props.dashdevices.Lights.title.replace(/ /g, '_')}
              query={this.props.dashdevices.Lights}
              options={{
                filtering: false,
                paging: false,
                showShowOnlyActive: true,
                actionsColumnIndex: -1
              }}
            />
          </Grid>
          {/* <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <Budget />
        </Grid> */}
          {/* <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TotalUsers />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TasksProgress />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TotalProfit />
        </Grid>
        <Grid
          item
          lg={8}
          md={12}
          xl={9}
          xs={12}
        >
          <LatestSales />
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          xl={3}
          xs={12}
        >
          <UsersByDevice />
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          xl={3}
          xs={12}
        >
          <LatestProducts />
        </Grid> */}
        </Grid>
      </div>
    );
  }
}

Dashboard.propTypes = {
  dashdevices: PropTypes.object
};

export default Dashboard;
