import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { default as contextUser, isAuthed } from 'common/contextUser';

//import { render } from 'node-sass';

const RouteWithLayout = props => {
  const { layout: Layout, component: Component, ...rest } = props;


  
  return (
    <contextUser.Consumer>
      {({ jwtToken }) => (
        <Route
          {...rest}
          render={matchProps =>
            isAuthed(jwtToken) ? (
              <Layout>
                {/* <Component query={props.query} options={props.options} {...rest} {...matchProps} /> */}
                <Component {...rest} {...matchProps} />
              </Layout>
            ) : (
              <Redirect
                to={{
                  pathname: '/sign-in',
                  state: { from: props.location }
                }}
              />
            )
          }
        />
      )}
    </contextUser.Consumer>
  );
  // }
  //})
};

// return (
//   <Route {...rest}
//     render={(matchProps) => (
//       AuthUtils.isAuthed() === true
//       ? <Layout>
//           <Component {...matchProps} />
//         </Layout>
//       : <Redirect to={{
//           pathname: '/sign-in',
//           state: { from: matchProps.location }
//         }} />
//     )}/>
// )

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

export default RouteWithLayout;
