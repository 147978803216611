import React from 'react';
import SingleTableView from 'components/SingleTableView';
import config from 'config';

const LogView = (props) => {
    const LogId = props.match.params.LogId;
    let logInfo = null;
    for (let i = 0; i < config.logs.length; i++)
        if (config.logs[i].log === LogId)
            logInfo = config.logs[i];

    return (
        <SingleTableView
            options={{ paging: true, filtering: true, pageSize: 10, pageSizeOptions: [10, 20, 30] }}
            query={logInfo}
        />
    )
}

export default LogView;