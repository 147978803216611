import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';

import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';
import queryString from "query-string";
import config from 'config';

import {default as contextUser, parseJwt, getToken, isAuthed } from 'common/contextUser';

const browserHistory = createBrowserHistory();


validate.validators = {
  ...validate.validators,
  ...validators
};



export default class App extends Component {
  constructor(props){
    super(props);
    // Logs client our by removing Context and localstorage
    this.logOut = () => {
      this.setState({
        user: {},
        jwtToken: ""
      });
      window.localStorage.setItem("jwtToken", "");
    }
    this.state = {
      user: {},
      logOut: this.logOut
    };
    
    if (typeof props.location != "undefined"){
      var query = queryString.parse(props.location.search);
      if (query.token) {
        this.state.jwtToken = query.token;
        this.state.user = parseJwt(query.token);
        window.localStorage.setItem("jwtToken", query.token);
        this.props.history.push("/");
      } else {
        const jwt = getToken();
        if (isAuthed(jwt))
        {
          this.state.jwtToken = jwt;
          this.state.user = parseJwt(jwt);
        }
      }
    }
  }

  render() {
    return (
      <contextUser.Provider value={this.state}>
        <ThemeProvider theme={theme}>
          <Router history={browserHistory}>
            <Routes config={config}/>
          </Router>
        </ThemeProvider>
      </contextUser.Provider>
      
    );
  }
}
