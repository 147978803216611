import React, { useState } from 'react';
import config from "../../config"
import { makeStyles } from '@material-ui/styles';
import { CircularProgress, TextField } from '@material-ui/core';
import Axios from 'axios';


import {
    Card,
    CardHeader,
    CardContent,
    Grid,
    Divider,
    Button
} from '@material-ui/core';

//import { Notifications, Password } from './components';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    }
}));

const Settings = () => {
    const [isLoadingSync, setIsLoadingSync] = useState(false);
    const [isLoadingCopyDB, setIsLoadingCopyDb] = useState(false);
    const [isLoadingUpdateStatusNum, setIsLoadingUpdateStatusNum] = useState(false);
    const [results, setResults] = useState(null);
    const classes = useStyles();

    const handleCopyDb = () => {
        setIsLoadingCopyDb(true)
        setResults(null);
        Axios.get(config.SetupCopyDB).then((res) => {
            setResults(res.data);
            setIsLoadingCopyDb(false);
        })
    }

    const handleUpdateStatusNum = () => {
        setIsLoadingUpdateStatusNum(true)
        setResults(null);
        Axios.get(config.SetupUpdateStatusNum).then((res) => {
            setResults(res.data);
            setIsLoadingUpdateStatusNum(false);
        })
    }

    const handleResync = () => {
        setIsLoadingSync(true)
        setResults(null);
        Axios.get(config.SetupHaiSync).then((res) => {
            setResults(res.data);
            setIsLoadingSync(false);
        })
    }
    const parseLines = (value) => value.replace(/(\\n)/g, "\n");


    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={4}
            >
                <Grid
                    item
                    md={7}
                    xs={12}
                >
                    <Card>
                        <CardHeader
                            subheader=""
                            title="Actions"
                        />
                        <Divider />
                        <CardContent>
                            <Grid
                                container
                                spacing={6}
                                wrap="wrap"
                            >
                                <Grid
                                    className={classes.item}
                                    item
                                    md={4}
                                    sm={6}
                                    xs={12}
                                >
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={handleCopyDb}
                                    >
                                        {isLoadingCopyDB ? <CircularProgress color="secondary" /> : null}
                                        Copy Data from Live DB to Test DB
                                    </Button>
                                </Grid>
                                <Grid
                                    className={classes.item}
                                    item
                                    md={4}
                                    sm={6}
                                    xs={12}
                                >
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        onClick={handleResync}
                                    >
                                        {isLoadingSync ? <CircularProgress color="primary" /> : null}
                                            ReSync Hai Security Panel Devices
                                    </Button>
                                </Grid>
                                <Grid
                                    className={classes.item}
                                    item
                                    md={4}
                                    sm={6}
                                    xs={12}
                                >
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={handleUpdateStatusNum}
                                    >
                                        {isLoadingUpdateStatusNum ? <CircularProgress color="secondary" /> : null}
                  Update Security Status Num
                </Button>
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    sm={12}
                                    xs={12}
                                >

                                    {// If results is not empty, show results field
                                        typeof results !== "undefined" && results !== null && results.length > 0 ?
                                            <TextField
                                                id="outlined-multiline-static"
                                                label="Results"
                                                multiline
                                                rows={10}
                                                value={parseLines(results)}
                                                variant="outlined"
                                                fullWidth
                                                inputProps={
                                                    { readOnly: true, }
                                                }
                                            /> : null}

                                </Grid>
                            </Grid>

                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default Settings;
