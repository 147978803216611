import React, { Component } from 'react';
import MaterialTable, { MTableToolbar, MTableFilterRow } from '@material-table/core';
import { Switch } from 'react-materialize';
import Axios from 'axios';
//import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import pluralize from 'pluralize';
import { Typography } from '@material-ui/core';
import FullScreenDialog from './FullScreenDialog';

//import styles from '../styles/theme';

//const backgroundShape = require('../images/shape.svg');

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.grey['100'],
        overflow: 'hidden',
        // background: `url(${backgroundShape}) no-repeat`,
        backgroundSize: 'cover',
        backgroundPosition: '0 400px',
        paddingBottom: 10
    },
    grid: {
        width: '100%',
        marginTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 20px)'
        }
    },
    paper: {
        padding: theme.spacing(3),
        textAlign: 'left',
        color: theme.palette.text.secondary
    },
    rangeLabel: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: theme.spacing(2)
    },
    topBar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 32
    },
    outlinedButtom: {
        textTransform: 'uppercase',
        margin: theme.spacing(1)
    },
    actionButtom: {
        textTransform: 'uppercase',
        margin: theme.spacing(1),
        width: 152
    },
    blockCenter: {
        padding: theme.spacing(2),
        textAlign: 'center'
    },
    block: {
        padding: theme.spacing(2)
    },
    box: {
        valign: 'top',
        marginBottom: 40,
        height: 65
    },
    inlining: {
        display: 'inline-block',
        marginRight: 10
    },
    buttonBar: {
        display: 'flex'
    },
    alignRight: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    noBorder: {
        borderBottomStyle: 'hidden'
    },
    loadingState: {
        opacity: 0.05
    },
    loadingMessage: {
        position: 'absolute',
        top: '40%',
        left: '40%'
    }
});

const isToday = function (date) {
    return moment(date, 'L')
        .local()
        .startOf('day')
        .isSame(
            moment()
                .local()
                .startOf('day')
        );
};

class RemoteDataTable extends Component {
    tableRef = React.createRef();

    constructor(props) {
        super(props);
        let options = {
            sorting: true,
            filtering: false,
            search: false,
            padding: 'dense',
            headerStyle: {
                backgroundColor: '#01579b',
                color: '#FFF'
            }
        };

        // Add or replace options for MaterialTable
        if (typeof props.options != 'undefined') {
            for (let [key, value] of Object.entries(this.props.options)) {
                options[key] = value;
            }
        }

        if (JSON.stringify(this.props.query) === '{}')
            alert("Query is Empty");

        this.state = {
            data: [],
            options: options,
            isLoading: false,
            actionNextDay: this.actionNextDay,
            actionPreviousDay: this.actionPreviousDay,
            filter: this.props.query.filter,
            error: null,
            showShowOnlyActive:
                this.props.options && this.props.options.showShowOnlyActive
                    ? this.props.options.showShowOnlyActive
                    : false,
            dialogOpen: false,
            dialogTitle: "",
            dialogData: {},
            dialogViewType: "raw"  // determines how to display data in dialog: raw = display raw string, "table"= get history of row data and 
            // display table.  "chart" = get history of row data and display on chart
            //Automated: this.props.query.filter && this.props.query.filter.Automated ? this.props.query.filter.Automated : false
        };
    }

    componentDidMount() {
        this.LoadData();

        // Set StatusNum filter to 1 if ShowOnlyActive, otherwise clear
        let filter = this.state.showShowOnlyActive ? 1 : null;

        this.tableRef.current.dataManager.columns.forEach(column => {
            if (column.field === 'StatusNum') column.tableData.filterValue = filter;
        });
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //   let options = {
    //     sorting: true,
    //     filtering: false,
    //     search: false,
    //     padding: 'dense',
    //     headerStyle: {
    //       backgroundColor: '#01579b',
    //       color: '#FFF'
    //     }
    //   };

    //   if (this.props.options !== prevProps.options) {
    //     // Add or replace options for MaterialTable

    //     if (typeof this.props.options != 'undefined') {
    //       for (let [key, value] of Object.entries(this.props.options)) {
    //         options[key] = value;
    //       }
    //     }
    //   } else options = this.props.options;

    //   if (this.props.query !== prevProps.query) {
    //     this.setState({
    //       options: options,
    //       filter: this.props.query.filter
    //     });

    //     this.LoadData();
    //   }
    // }

    handleIconClick(rowData, event) {
        alert('Right Iconclick!');
    }

    actionShowRawData = {
        icon: 'text_snippet',
        tooltip: 'Show Data',
        hidden: false,
        onClick: (event, rowData) => {
            this.setState({ dialogData: JSON.stringify(rowData, null, 3) });
            this.setState({ dialogOpen: true });
            this.setState({ dialogTitle: rowData.Name });
            this.setState({ dialogViewType: "raw" });
        }
    }

    actionShowHistoryTable = {
        icon: 'table_rows',
        tooltip: 'Show History',
        hidden: false,
        onClick: (event, rowData) => {
            this.setState({ dialogData: JSON.stringify(rowData, null, 3) });
            this.setState({ dialogOpen: true });
            this.setState({ dialogTitle: rowData.Name });
            this.setState({ dialogViewType: "table" })
        }
    }
    actionShowHistoryChart = {
        icon: 'insert_chart',
        tooltip: 'Show History Chart',
        hidden: false,
        onClick: (event, rowData) => {
            this.setState({ dialogData: JSON.stringify(rowData, null, 3) });
            this.setState({ dialogOpen: true });
            this.setState({ dialogTitle: rowData.Name });
            this.setState({ dialogViewType: "chart" })
        }
    }

    actionNextDay = {
        icon: 'navigate_next',
        tooltip: 'Next Day',
        isFreeAction: true,
        hidden: false,
        onClick: event => {
            let ds = '';
            if (typeof this.state.filter.date != 'undefined') ds = 'date';
            else if (typeof this.state.filter.Date != 'undefined') ds = 'Date';
            else if (typeof this.state.filter.timestamp != 'undefined')
                ds = 'timestamp';
            else return; // no Date found  dont do anything

            let t = moment(this.state.filter[ds]).local();
            //this.state.filter[ds] = t.add(1, 'days')format('YYYY-MM-DD');
            let f = this.state.filter;
            f[ds] = t.add(1, 'days').format('YYYY-MM-DD');
            this.setState({ filter: f });

            // this.setState(({ filter }) => ({
            //   filter: {
            //     ...filter,
            //     ds: t.add(1, 'days').format('YYYY-MM-DD')
            //   }
            // }));
            //this.setState({ isLoading: false });
            this.LoadData();
        }
    };

    actionPreviousDay = {
        icon: 'navigate_before',
        tooltip: 'Previous Day',
        isFreeAction: true,
        hidden: false,
        onClick: event => {
            let ds = '';
            if (typeof this.state.filter.date != 'undefined') ds = 'date';
            else if (typeof this.state.filter.Date != 'undefined') ds = 'Date';
            else if (typeof this.state.filter.timestamp != 'undefined')
                ds = 'timestamp';
            else return; // no Date found  dont do anything

            let t = moment(this.state.filter[ds]).local();
            //this.state.filter[ds] = t.subtract(1, 'days').format('YYYY-MM-DD');
            let f = this.state.filter;
            f[ds] = t.subtract(1, 'days').format('YYYY-MM-DD');
            this.setState({ filter: f });
            // this.setState(({ filter }) => ({
            //   filter: {
            //     ...filter,
            //     ds: t.subtract(1, 'days').format('YYYY-MM-DD')
            //   }}));
            //this.setState({ isLoading: false });
            this.LoadData();
        }
    };

    editable = {
        onRowUpdate: (newData, oldData) => {
            let t = this;
            return new Promise((resolve, reject) => {
                let DBname = pluralize.singular(t.props.query.title);
                let deviceId = newData.id || newData._id;
                Axios.put(t.props.query.apiUpdate + deviceId, newData)
                    .then(function (response) {
                        if (response.data.success) {
                            //alert("Updating "+ DBname+ " Succeeded "  );
                            t.LoadData();
                            resolve();
                        } else {
                            alert(
                                'Failed to Update ' + DBname + ' - ' + response.data.message
                            );
                            reject();
                        }
                    })
                    .catch(function (error) {
                        alert('Failed to Update ' + DBname + ' - ' + error.message);
                        reject();
                    });
            });
        },

        onRowDelete: oldData => {
            let t = this;
            let DBname = pluralize.singular(this.props.query.title);
            return new Promise((resolve, reject) => {
                Axios.delete(t.props.query.apiUpdate + oldData._id, {
                    data: oldData
                })
                    .then(function (response) {
                        if (response.data.success) {
                            t.LoadData();
                            resolve();
                        } else {
                            let m =
                                'Failed to Delete Record from ' +
                                DBname +
                                ' - ' +
                                JSON.stringify(oldData) +
                                ' == ' +
                                response.data.message;
                            alert(m);
                            reject();
                        }
                    })
                    .catch(function (err) {
                        alert(
                            'Failed to Delete Record from ' + DBname + ' - ' + err.message
                        );
                        reject();
                    });
            });
        },
        onRowAdd: newData => {
            let t = this;
            let DBname = pluralize.singular(this.props.query.title);
            return new Promise((resolve, reject) => {
                Axios.post(t.props.query.apiUpdate, newData)
                    .then(function (response) {
                        if (response.data.success) {
                            t.LoadData();
                            resolve();
                        } else {
                            let m =
                                'Failed to Add Record from ' +
                                DBname +
                                ' - ' +
                                JSON.stringify(newData) +
                                ' == ' +
                                response.data.message;
                            alert(m);
                            reject(m);
                        }
                    })
                    .catch(function (err) {
                        let m =
                            'Failed to Delete Record from ' + DBname + ' - ' + err.message;
                        alert(m);
                        reject(m);
                    });
            });
        }
    };

    // This changes the default filter to 1 (setup of columns must include
    // custom filter handler that customFilterAndSearch: (term, rowData) => term => rowData.StatusNum
    // This sets the column.defaultFilter to 1 for On, 0 for Off
    handleShowOnlyActive = event => {
        this.LoadData();

        this.setState({ showShowOnlyActive: event.target.checked });
        this.tableRef.current.dataManager.columns.forEach(column => {
            if (column.field === 'StatusNum')
                column.tableData.filterValue = event.target.checked ? '1' : null;
        });

    };

    // This changes the default filter to 1 (setup of columns must include
    // custom filter handler that customFilterAndSearch: (term, rowData) => term => rowData.StatusNum
    // This sets the column.defaultFilter to 1 for On, 0 for Off
    handleFilterByShowAutomatedChange = event => {
        let f = this.state.filter;
        f.Automated = event.target.checked;
        this.setState({ filter: f });
        //this.setState({Automated: event.target.checked })
        // this.tableRef.current.dataManager.columns.forEach(column => {
        //   if (column.field === 'Automated')
        //     column.tableData.filterValue = event.target.checked ? 'False' : null;
        // });

        this.LoadData();
    };

    handleShow = event => {
        let b = event.target.checked;
        let o = this.state.options;
        if (event.target.id.startsWith('ShowSearch')) o.search = b;
        else if (event.target.id.startsWith('ShowFilter')) o.filtering = b;
        this.setState({ options: o });
    };

    components = {
        FilterRow: props => <MTableFilterRow {...props} />,
        Toolbar: props => (
            <div>
                <div>
                    <MTableToolbar {...props} />
                    {this.state.error === null ? ( // if there is an Error, show Error in ToolBar, otherwise show toolbar
                        <div style={{ padding: '10px', display: 'flex' }}>
                            {/* <Switch hidden= offLabel={props.title + ": All"} onLabel="On" onChange= {this.handleShowOnlyActive} /> */}
                            {this.props.query.ShowOnlyActive ? (
                                <Switch
                                    key={'ShowOnlyActive' + props.title.replace(/ /g, '_')}
                                    id={'ShowOnlyActive' + props.title.replace(/ /g, '_')}
                                    offLabel={'Show Only Active'}
                                    // offLabel={
                                    //   'Show ' +
                                    //   props.title +
                                    //   ': ' +
                                    //   this.props.query.componentOnOffLabels.Off
                                    // }
                                    // onLabel=""{this.props.query.componentOnOffLabels.On}
                                    onLabel=""
                                    onChange={this.handleShowOnlyActive}
                                    style={{ marginRight: 10 }}
                                    checked={this.state.showShowOnlyActive}
                                />
                            ) : (
                                    <div> </div>
                                )}
                            {this.props.query.title === 'Lights' ? (
                                <Switch
                                    id={'ShowAutomatedLights' + props.title}
                                    offLabel={'Show Automated Devices'}
                                    // offLabel={
                                    //   'Show ' +
                                    //   props.title +
                                    //   ': ' +
                                    //   this.props.query.componentOnOffLabels.Off
                                    // }
                                    // onLabel=""{this.props.query.componentOnOffLabels.On}
                                    onLabel=""
                                    onChange={this.handleFilterByShowAutomatedChange}
                                    style={{ marginRight: 10 }}
                                    // If True, show Automated Devices/Lights in List
                                    checked={
                                        this.props.query.filter && this.props.query.filter.Automated
                                    }
                                />
                            ) : (
                                    <div> </div>
                                )}
                            <Switch
                                id={'ShowSearch' + props.title}
                                offLabel={'Show Search:'}
                                onLabel=""
                                onChange={this.handleShow}
                                style={{ marginRight: 10 }}
                            />{' '}
                            <Switch
                                id={'ShowFilter' + props.title}
                                offLabel={'Show Filter:'}
                                onLabel=""
                                onChange={this.handleShow}
                                style={{ marginRight: 10 }}
                            />{' '}
                        </div>
                    ) : (
                            <div style={{ padding: '10px', align: 'center' }}>
                                <Typography variant="h3" align="center" color="error">
                                    {' '}
                                    {this.state.error}
                                </Typography>
                            </div>
                        )}
                </div>
            </div>
        )
    };

    // if id of clicked item matches action, do the action
    RowClick = (event, rowData) => {
        switch (event.target.parentNode.id) {
            case 'DeviceToggle':
                let newData = JSON.parse(JSON.stringify(rowData));
                newData.StatusNum = rowData.StatusNum ? 0 : 1;
                this.editable.onRowUpdate(newData, rowData);
                //alert("Device Change Status Event- Type = ["+rowData.Type+"] Name=["+rowData.Name+"]");
                break;
            case 'url':
                break;
            default:
            //alert(JSON.stringify(rowData, null, 3));
        }
    };

    LoadData() {
        this.setState({ isLoading: true });
        // Table has date, add change date UI features
        this.actionNextDay.hidden = true;
        this.actionPreviousDay.hidden = true;

        let d = null;
        if (typeof this.state.filter != 'undefined') {
            if ('date' in this.state.filter) d = 'date';
            else if ('Date' in this.state.filter) d = 'Date';
            else if ('timestamp' in this.state.filter) d = 'timestamp';
            if (d != null) {
                this.actionPreviousDay.hidden = false;
                // check to see if is today
                this.actionNextDay.hidden = isToday(this.state.filter[d]);
            }
        }

        this.setState({ actionNextDay: this.actionNextDay });
        this.setState({ actionPreviousDay: this.actionPreviousDaytrue });

        Axios.get(this.props.query.api, {
            params: { data: this.state.filter }
        })
            .then(result => {
                this.setState({ data: result.data.data });
                this.setState({ isLoading: false });
                this.setState({ error: null });
            })
            .catch(error => {
                //this.setState({ data: ["Error: - "+ error.message], isLoading: false });
                console.log('RemoteDataTable Error - ', error.message);
                this.setState({ isLoading: false });
                this.setState({ error: error.message });
            });
    }

    // Takes a object {key: <key>, data: <value>}, this is then added to the filter.key array for that filter item
    // changeFilter(data){

    render() {
        
        return (
            <div className={styles.box}>
                <FullScreenDialog dialogOpen={this.state.dialogOpen} title={this.state.dialogTitle} data={this.state.dialogData} dialogViewType={this.state.dialogViewType} Close={() => { this.setState({ dialogOpen: false }) }} />
                <MaterialTable
                    tableRef={this.tableRef}
                    key={'MT' + this.props.query.title.replace(/ /g, '_')}
                    title={this.props.query.title}
                    size="small"
                    style={{ valign: 'top', height: '100%' }}
                    options={this.state.options}
                    actions={[this.actionPreviousDay, this.actionNextDay, this.actionShowRawData, this.actionShowHistoryTable, this.actionShowHistoryChart]}
                    columns={this.props.query.columns}
                    editable={this.props.query.editable ? this.editable : {}}
                    data={this.state.data}
                    onRowClick={this.RowClick}
                    components={this.components}
                    isLoading={this.state.isLoading}
                    stickyHeader>
                </MaterialTable>
            </div>
        );

        // {
        //   <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        //         <Button color='primary' variant="contained" className={styles.actionButtom}>
        //           See All Events
        //         </Button>
        //       </div>
        // }
        // </Paper>
    }
}

export default RemoteDataTable;
