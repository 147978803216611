import React from 'react';
import { makeStyles } from '@material-ui/styles';
//import "react-table/react-table.css";
import RemoteDataTable from '../../components/RemoteDataTable';

import config from '../../config';

//import { UsersToolbar, UsersTable } from './components';
//import mockData from './data';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const UserList = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {/* <RemoteDataTable query={config.RemoteDataTables.DashSecurity} options={{filtering: true, pageSize: 10, pageSizeOptions: [10,20,30]}}  /> */}
        <RemoteDataTable key="UserListUsers" query={config.RemoteDataTables.DashUser.UserList} />
      <RemoteDataTable key="userListLogins" query={config.RemoteDataTables.DashUser.Logins}   />

      </div>
    </div>
  );
};

export default UserList;
