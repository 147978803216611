import React from 'react';

import IconLock from '@material-ui/icons/Lock';
//import LockOpenIcon from '@material-ui/icons/LockOpen';
import IconBulb from '@material-ui/icons/WbIncandescent';
import IconMotion from '@material-ui/icons/DirectionsRun';
import IconUnknown from '@material-ui/icons/Help';
import IconTemperature from '@material-ui/icons/AcUnit';
//import IconHumidity from '@material-ui/icons/Opacity';
import IconFlag from '@material-ui/icons/Flag';
import IconPower from '@material-ui/icons/Power';
import IconHome from '@material-ui/icons/Home';
import MdiIcon from '@mdi/react';
import {
  mdiFire,
  mdiWaterPercent,
  mdiWater,
  mdiHomeAutomation,
  mdiAlarmLight,
  mdiThermometerLines
} from '@mdi/js';

class DeviceIcon extends React.Component {
  render() {
    let col = 'grey';
    let type = this.props.rowdata.TypeGroup || this.props.rowdata.Type;

      switch (type) {
          case 'Area':
              col = this.props.rowdata.StatusNum ? 'red' : 'green';
              return (
                  <IconHome
                      fontSize={this.props.size}
                      style={{ color: col }}>
                  </IconHome>);
      case 'Lights':
      case 'Extended':
      case 'HLC_Room':
      case 'HLC_Load':
      case 'UPB':
        col = this.props.rowdata.StatusNum ? 'yellow' : 'grey';
        return (
          <IconBulb
            id="DeviceToggle"
            fontSize={this.props.size}
            style={{ color: col }}></IconBulb>
        );
      case 'Motion':
      case 'Away_Interior':
        col = this.props.rowdata.StatusNum ? 'red' : 'green';
        return (
            <IconMotion
                fontSize={this.props.size}
                style={{ color: col }}>
            </IconMotion>
        );
      case 'Security':
      case 'Entry_Exit':
      case 'Quadruple_Entry_Delay':
      case 'Perimeter':
        col = this.props.rowdata.StatusNum ? 'red' : 'green';
        return <IconLock fontSize={this.props.size} style={{ color: col }}></IconLock>;
      case 'Fire':
        col = this.props.rowdata.StatusNum ? 'red' : 'grey';
        return (
          <MdiIcon path={mdiFire} size={2} style={{ color: col }}></MdiIcon>
        );
      case 'Temperature':
      case 'Extended_Range_Temperature':
      case 'Extended_Range_Outdoor_Temperature':
        if (this.props.rowdata.StatusNum > 75) col = 'red';
        else if (this.props.rowdata.StatusNum < 40) col = 'blue';
        else col = 'green';
        return (
            <IconTemperature
                fontSize={this.props.size}
                style={{ color: col }}>
            </IconTemperature>
          );
      case 'Thermostat':
        if (this.props.rowdata.Status.toLowerCase().includes("idle")) col = 'grey';
        else if (this.props.rowdata.Status.toLowerCase().includes("heating")) col = 'red';
        else if (this.props.rowdata.Status.toLowerCase().includes("cooling")) col = 'blue';
        else col = 'grey';
        return (
          <MdiIcon  path={mdiThermometerLines} size={1 } style={{ color: col }} ></MdiIcon>
        );        
      case 'Humidity':
        if (this.props.rowdata.StatusNum > 60) col = 'red';
        else if (this.props.rowdata.StatusNum < 30) col = 'green';
        else col = 'blue';
        return (
          <MdiIcon
            path={mdiWaterPercent}
            size={2}
            style={{ color: col }}></MdiIcon>
        );
      //return <IconHumidity fontSize = "large" style={{color:col}}></IconHumidity>
      case 'Water':
        col = this.props.rowdata.StatusNum ? 'red' : 'grey';
        return (
          <MdiIcon path={mdiWater} size={2} style={{ color: col }}></MdiIcon>
        );
      case 'Auxiliary':
        col = this.props.rowdata.StatusNum ? 'yellow' : 'grey';
        return (
          <MdiIcon
            path={mdiHomeAutomation}
            size={2}
            style={{ color: col }}></MdiIcon>
        );
      case 'Freeze':
        col = this.props.rowdata.StatusNum ? 'red' : 'grey';
        return (
          <MdiIcon
            path={mdiAlarmLight}
            size={2}
            style={{ color: col }}></MdiIcon>
        );
      case 'Flag':
        col = this.props.rowdata.StatusNum ? 'green' : 'grey';
        return (
          <IconFlag
            id="DeviceToggle"
            fontSize={this.props.size}
            style={{ color: col }}></IconFlag>
        );
      //return <IconFlag onClick={IconClick.bind(this, this.props.rowdata)} fontSize = "large" style={{color:col}}></IconFlag>
      case 'Output':
        col = this.props.rowdata.StatusNum ? 'green' : 'grey';
        return (
          <IconPower
            id="DeviceToggle"
            fontSize={this.props.size}
            style={{ color: col }}></IconPower>
        );
      //return <IconFlag onClick={IconClick.bind(this, this.props.rowdata)} fontSize = "large" style={{color:col}}></IconFlag>

      default:
        return (
            <IconUnknown
                fontSize={this.props.size}
                style={{ color: 'orange' }}>
            </IconUnknown>
        );
    }
  }
}

DeviceIcon.defaultProps = {
  size: "large",
};

export default DeviceIcon;
