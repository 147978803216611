import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Badge, Hidden, IconButton, Typography } from '@material-ui/core';

//import MenuIcon from '@material-ui/icons/Menu';
//import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import { NotificationsOutlined as NotificationsIcon, Input as InputIcon, Settings as SettingsIcon, Menu as MenuIcon } from '@material-ui/icons';
import contextUser from 'common/contextUser'

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  }
}));

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;

  const classes = useStyles();

  const [notifications] = useState([]);
  let history = useHistory();

  // const handleLogOut = () => {
  //   localStorage.removeItem("jwtToken");
  //   this.setState({});
  // }

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <RouterLink to="/">
          {/* <h3 style={{ color: 'white' }}>BKHome</h3> */}
          <Typography variant="h1" style={{ color: 'white' }}> BKHome</Typography>
          {/* <img
            alt="Logo"
            //src="/images/logos/logo--white.svg"
          /> */}
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton
            color="inherit"
            tooltip="Log Out"
            onClick={() => history.push("/settings")}
          >
           
            {/* <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
            
            </Badge> */}
            <SettingsIcon />
          </IconButton>
          <contextUser.Consumer>{({ logOut }) => (
              <IconButton
              className={classes.signOutButton}
              color="inherit"
              tooltip="Log Out"
              onClick={logOut}
            >
              <InputIcon />
            </IconButton>
          )}</contextUser.Consumer>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
