import React from 'react';
import * as moment from 'moment';
import DeviceIcon from 'components/DeviceIcon';

import pluralize from 'pluralize';
//import { getNodeMajorVersion } from 'typescript';

// const styles = theme => ({
//   root: {
//     padding: theme.spacing(3)
//   },
//   content: {
//     marginTop: theme.spacing(2)
//   },
//   icon: {
//     textAlign: 'center'
//   }
// });

function isToday(momentDate) {
  momentDate.local();
  return momentDate.isSame(moment().startOf('day'), 'd');
}
function isYesterday(momentDate) {
  momentDate.local();
  return momentDate.isSame(
    moment()
      .subtract(1, 'days')
      .startOf('day'),
    'd'
  );
}
function isWithinAWeek(momentDate) {
  momentDate.local();
  let r = momentDate.isAfter(
    moment()
      .subtract(7, 'days')
      .startOf('day')
  );
  return r;
}

function formatDate(dt) {
  if (typeof dt == 'undefined') return 'NA';
  let t = moment(dt).local();

  if (isToday(t)) return t.format('LTS');
  else if (isYesterday(t)) return t.format('LTS') + ' Yesterday';
  else if (isWithinAWeek(t)) return t.format('LTS') + ' ' + t.format('dddd');
  else return t.format('llll');
}

//  function IconClick(con, event){
//    console.log(con)
//    alert(con);
//    //alert("Icon ["+rowdata.Name+"] Type "+ rowdata.Type + " Clicked");
//   //  event.target.style.color = "red";
//   event.stopPropagation();
//  }

// TODO: Need to figure out how to handle typegroup if it is not
// part of the data

// let listDashDevices = [
//   {
//     _id: 1
//   }

// ];

let securitycolumns = [
  {
    title: 'Device',
    field: 'Name'
  },
  {
    title: 'Type',
    field: 'TypeGroup',
    lookup: {
      Security: 'Security',
      Lights: 'Lights',
      Motion: 'Motion',
      Temperature: 'Temperature',
      Humidity: 'Humidity',
      Luminance: 'Luminance',
      Water: 'Water',
      System: 'System',
      Automation: 'Automation',
      Flag: 'Flag',
      Output: 'Output',
      Audio: 'Audio',
      Energy: 'Energy',
      Area: 'Area'
    }
  },
  {
    title: 'Icon',
    field: 'Status',
    //render: rowData => <div>{geticon(rowData,this)}</div>,
    render: rowData => <DeviceIcon rowdata={rowData} />,
    filtering: false
    //render: rowData => <IconLock fontSize={"large"} style={{color:'red'}}></IconLock>
  },
  {
    title: 'Status',
    field: 'Status'
  },
  {
    title: 'StatusNum',
    field: 'StatusNum',
    hidden: true
  },
  {
    title: 'Date',
    field: 'Date',
    type: 'date',
    render: rowData => <div>{formatDate(rowData.Date)}</div>
  }
];
// Clone securitycolumns so can extend for eventlogColumns
let eventLogColumns = [...securitycolumns];

eventLogColumns.push({
  title: 'Event Number',
  field: 'EventNumber'
});
eventLogColumns.push({
  title: 'User',
  field: 'UserName'
});

let usercolumns = [
  {
    title: 'Name',
    field: 'name',
    editable: 'onAdd'
  },
  {
    title: 'Role',
    field: 'userRole',
    editable: 'always',
    lookup: {}
  },
  {
    title: 'Email Address',
    editable: 'onAdd',
    field: 'email'
  }
];

// Axios.get('/api/userRoles')
//   .then(result => {
//     let lookup = {};
//     result.data.forEach(role => {
//       lookup[role] = role;
//     });

//     for (let i = 0; i < usercolumns.length; i++) {
//       if (usercolumns[i].field === 'userRole') usercolumns[i].lookup = lookup;
//     }
//   })
//   .catch(function(error) {
//     console.log('Failed to get userRoles in Config - ' + error.messsage);
//   });

let loginscolumns = [
  {
    title: 'Date',
    field: 'date',
    type: 'date',
    render: rowData => <div>{formatDate(rowData.date)}</div>
  },
  {
    title: 'Name',
    field: 'name'
  },
  {
    title: 'Email Address',
    field: 'email'
  },
  {
    title: 'Success',
    field: 'success',
    type: 'boolean'
  }
];

let columnsZones = [
  {
    title: 'Icon',
    field: 'Status',
    //render: rowData => <div>{geticon(rowData, this)}</div>,
    render: rowData => <DeviceIcon rowdata={rowData} />,
    filtering: false,
    editable: 'never'
    //render: rowData => <IconLock fontSize={"large"} style={{color:'red'}}></IconLock>
  },
  {
    title: 'Name',
    field: 'Name',
    editable: 'never'
  },
  {
    title: 'Description',
    field: 'Descrition'
  },
  {
    title: 'Zone Type',
    field: 'Type',
    editable: 'never'
  },

  {
    title: 'Status',
    field: 'Status',
    editable: 'never'
  },
  {
    field: 'StatusNum',
    title: 'StatusNum',
    hidden: true,
    filtering: true,
    customFilterAndSearch: (term, rowData) => {
      return parseInt(rowData.StatusNum) >= parseInt(term);
    },
    editable: 'never'
  },
  {
    title: 'Last Updated',
    field: 'updatedAt',
    type: 'date',
    render: rowData => <div>{formatDate(rowData.updatedAt)}</div>,
    editable: 'never'
  },
  {
    title: 'Zone Loop Status',
    field: 'ZoneLoopStatus',
    type: 'numeric'
  },
  {
    title: 'Show in Dashboard',
    field: 'ShowInDashboard',
    type: 'numeric'
  }
];

let columnsAreas = [
  {
    title: 'Icon',
    field: 'Status',
    //render: rowData => <div>{geticon(rowData, this)}</div>,
    render: rowData => <DeviceIcon rowdata={rowData} />,
    filtering: false,
    editable: 'never'
    //render: rowData => <IconLock fontSize={"large"} style={{color:'red'}}></IconLock>
  },
  {
    title: 'Name',
    field: 'Name',
    editable: 'never'
  },
  {
    title: 'Description',
    field: 'Description'
  },
  {
    title: 'Status',
    field: 'Status',
    editable: 'never'
  },
  {
    title: 'Alarm Status',
    field: 'AlamsStatus',
    editable: 'never'
  },
  {
    title: 'Last Updated',
    field: 'updatedAt',
    type: 'date',
    render: rowData => <div>{formatDate(rowData.updatedAt)}</div>,
    editable: 'never'
  },
  {
    title: 'Show in Dashboard',
    field: 'ShowInDashboard',
    type: 'numeric'
  }
];

let columnsUnits = [
  {
    title: 'Icon',
    field: 'Status',
    //render: rowData => <div>{geticon(rowData, this)}</div>,
    render: rowData => <DeviceIcon rowdata={rowData} />,
    filtering: false,
    //render: rowData => <IconLock fontSize={"large"} style={{color:'red'}}></IconLock>
    editable: 'never'
  },
  { field: 'Name', title: 'Name', editable: 'never' },
  { field: 'Description', title: 'Description' },
  { field: 'Type', title: 'Type', editable: 'never' },

  { field: 'Status', title: 'Status', editable: 'never' },
  {
    field: 'StatusNum',
    title: 'StatusNum',
    filtering: true,
    hidden: true,
    editable: 'never',
    customFilterAndSearch: (term, rowData) => {
      return parseInt(rowData.StatusNum) >= parseInt(term);
    }
  },
  {
    field: 'SecondsRemainingStatus',
    title: 'Seconds Remaining',
    type: 'numeric',
    editable: 'never'
  },
  {
    title: 'Last Updated',
    field: 'updatedAt',
    type: 'date',
    editable: 'never',
    render: rowData => <div>{formatDate(rowData.updatedAt)}</div>
  },
  {
    title: 'Show in Dashboard',
    field: 'ShowInDashboard',
    type: 'numeric'
  },
  {
    field: 'Automated',
    title: 'Automation Controlled',
    filtering: true,
    hidden: false,
    editable: 'always',
    lookup: {
      true: 'True',
      false: 'False'
    }
  }
];

let columnsSensors = [
  {
    title: 'Last Updated',
    field: 'updatedAt',
    type: 'date',
    render: rowData => <div>{formatDate(rowData.updatedAt)}</div>,
    editable: 'never'
  },
  { field: 'Name', title: 'Name', editable: 'never' },
  { field: 'Number', title: 'Number', editable: 'never' },
  { field: 'Type', title: 'Type', editable: 'never' },
  { field: 'Status', title: 'StatusValue', editable: 'never' },
  {
    title: 'Show in Dashboard',
    field: 'ShowInDashboard',
    type: 'numeric'
  }
];


let columnsMDNS = [
  {
    field: 'updatedAt',
    title: 'Last Updated',
    type: 'date',
    editable: 'never'
  },
  { field: 'Name', title: 'Name', editable: 'never' },
  { field: 'Description', titel: 'Description' },
  {
    field: 'Type',
    title: 'Type',
    lookup: {
      Camera: 'Camera',
      Thermostat: 'Thermostat',
      Temperature: 'Temperature',
      'Mobile Device': 'Mobile Device',
      Computer: 'Computer',
      'TV-DVR': 'TV-DVR',
      'Sprinkler Controller': 'Sprinkler Controller',
      Printer: 'Printer',
      'Airplay Speakers': 'Airplay Speakers',
      'Audio Source': 'Audio Source',
      Light: 'Light',
      Room: 'Room'
    }
  },
  {
    field: 'path',
    title: 'Path',
    editable: 'never',
    render: function(rowdata) {
      if (typeof rowdata.path == 'string' && rowdata.path.startsWith('http'))
        return (
          <div id="url">
            <a target="_blank" rel="noopener noreferrer" href={rowdata.path}>
              {rowdata.path}
            </a>
          </div>
        );
      else return <div>{rowdata.path}</div>;
    }
  },
  { field: 'protocol', title: 'Protocol', editable: 'never' },
  { field: 'Status', title: 'StatusValue', editable: 'never' },
  {
    title: 'Show in Dashboard',
    field: 'ShowInDashboard',
    type: 'numeric'
  }
];

let columnsButton = [
  {
    field: 'updatedAt',
    title: 'Last Updated',
    type: 'date',
    editable: 'never'
  },
  { field: 'Name', title: 'Name', editable: 'never' },
  { field: 'Number', title: 'Number', type: 'numeric', editable: 'never' },
  { field: 'Type', title: 'Type', editable: 'never' },
  { field: 'Status', title: 'Status', editable: 'never' },
  {
    field: 'Last_PushedStatus',
    title: 'Last Pushed',
    type: 'date',
    editable: 'never'
  },
  {
    title: 'Show in Dashboard',
    field: 'ShowInDashboard',
    type: 'numeric'
  }
];

let columnsMessage = [
  {
    field: 'updatedAt',
    title: 'Last Updated',
    type: 'date',
    editable: 'never'
  },
  { field: 'Name', title: 'Name', editable: 'never' },
  { field: 'Number', title: 'Number', type: 'numeric', editable: 'never' }
];

const securityApi = '/api/Security';
const userApi = '/api/Users';
const loginApi = '/api/Logins';
const logsApi = '/api/GetLogs';
const deviceApi = '/api/Device/';
const DashCardsApi = '/api/Setup/DashCards';

const logDefaultFilter = {
  timestamp: moment().format('YYYY-MM-DD'),
  count: 10000
};

const columnsLoggerLogs = [
  {
    field: 'timestamp',
    title: 'Date',
    type: 'date',
    render: rowData => <div>{formatDate(rowData.timestamp)}</div>
  },
  {
    field: 'level',
    title: 'Error Level',
    lookup: {
      error: 'Error',
      warn: 'Warning',
      info: 'Info',
      http: 'Http',
      verbose: 'Verbose',
      debug: 'Debug',
      silly: 'Silly'
    }
  },
  { field: 'hostname', title: 'Server' },
  { field: 'message', title: 'Message' }
];

const config = {
  SetupCopyDB: '/api/Setup/CopyDBData',
  SetupHaiSync: '/api/Setup/HaiSync',
  SetupUpdateStatusNum: '/api/Setup/UpdateStatusNum',

  // Loads Array of DB _ids that are displayed at the top of the dashboard

  deviceApi: deviceApi,

  loginUrl:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:8080/api/auth/google'
      : '/api/auth/google',

  // List of Log databases to show in Log Viewer
  //
  logs: [
    {
      title: 'BKHome Hai Security Panel Messages/Log',
      log: 'hai',
      columns: eventLogColumns,
      //api: logsApi+"/hai",
      api: '/api/EventLog',
      editable: false,
      filter: logDefaultFilter,
      ShowOnlyActive: false
    },
    {
      title: 'BKHome Error Log',
      log: 'BKHome_log',
      columns: columnsLoggerLogs,
      api: logsApi + '/BKHome_log',
      editable: false,
      filter: logDefaultFilter,
      ShowOnlyActive: false
    },
    {
      title: 'SmartThings Error Log',
      log: 'SmartThignsLog_log',
      columns: columnsLoggerLogs,
      api: logsApi + '/SmartThingsLog_log',
      editable: false,
      filter: logDefaultFilter,
      ShowOnlyActive: false
    },
    {
      title: 'Server WatchDog Log',
      log: 'watchdog_logs',
      columns: columnsLoggerLogs,
      api: logsApi + '/watchdog_logs',
      editable: false,
      filter: logDefaultFilter,
      ShowOnlyActive: false
    }
  ],
  AutomationList: {
    title: 'Automation List',
    columns: columnsZones,
    api: '/api/AutomationList',
  },
  RemoteDataTables: {
    DashSecurity: {
      title: 'Events',
      ShowOnlyActive: true,
      componentOnOffLabels: { On: 'Open', Off: 'All' },
      columns: securitycolumns,
      api: securityApi,
      editable: false,
      filter: {
        date: moment().format('YYYY-MM-DD'),
        multipleDays: true,
        count: 10000,
        TypeGroup: [],
        EventType: null,
        Type: [],
        Number: [],
        Name: [],
        Status: []
      }
    },
    
    DeviceList: {
      Zones: {
        ShowOnlyActive: true,
        componentOnOffLabels: { On: 'Open', Off: 'All' },
        title: 'Security Zones',
        columns: columnsZones,
        api: '/api/HomeStatus/Zone',
        apiUpdate: deviceApi,
        editable: true
      },
      Thermostats: {
        ShowOnlyActive: false,
        componentOnOffLabels: { On: 'Heating/Cooling', Off: 'Idle' },
        title: 'Thermostats',
        columns: columnsZones,
        api: '/api/HomeStatus/Thermostat',
        apiUpdate: deviceApi,
        editable: true
      },
      SewerPump: {
        ShowOnlyActive: false,
        componentOnOffLabels: { On: 'Running', Off: 'Off' },
        title: 'SewerPump',
        columns: securitycolumns,
        api: securityApi,
        //apiUpdate: deviceApi,
        editable: false,
        filter: {
          date: moment().format('YYYY-MM-DD'),
          count: 1000,
          TypeGroup: [],
          EventType: null,
          Type: [],
          Number: [],
          Name: ["Sewer Pump 1", "Sewer Pump 2"],
          Status: []
        }
      },
      Areas: {
        ShowOnlyActive: true,
        componentOnOffLabels: { On: 'Open', Off: 'All' },
        title: 'Security Areas',
        columns: columnsAreas,
        api: '/api/HomeStatus/Area',
        apiUpdate: deviceApi,
        editable: false
      },
      Lights: {
        title: 'Lights',
        ShowOnlyActive: true,
        componentOnOffLabels: { On: 'On', Off: 'All' },
        columns: columnsUnits,
        api: '/api/HomeStatus/Lights',
        apiUpdate: deviceApi,
        editable: true,
        filter: { Automated: false }
      },
      Flags: {
        title: 'Flags',
        columns: columnsUnits,
        ShowOnlyActive: true,
        componentOnOffLabels: { On: 'Set', Off: 'All' },
        api: '/api/HomeStatus/Flags',
        apiUpdate: deviceApi,
        editable: true
      },
      Outputs: {
        title: 'Outputs',
        columns: columnsUnits,
        ShowOnlyActive: true,
        componentOnOffLabels: { On: 'On', Off: 'All' },
        api: '/api/HomeStatus/Outputs',
        apiUpdate: deviceApi,
        editable: true
      },
      Cameras: {
        title: 'Cameras',
        columns: columnsMDNS,
        ShowOnlyActive: false,
        componentOnOffLabels: { On: 'Open', Off: 'All' },
        api: '/api/HomeStatus/Cameras',
        apiUpdate: deviceApi,
        editable: true
      },
      Sensors: {
        title: 'Sensors',
        columns: columnsSensors,
        ShowOnlyActive: false,
        componentOnOffLabels: { On: 'Open', Off: 'All' },
        api: '/api/HomeStatus/Auxiliary_Sensor',
        apiUpdate: deviceApi,
        editable: true
      },
      Buttons: {
        title: 'Hai Buttons',
        columns: columnsButton,
        ShowOnlyActive: false,
        componentOnOffLabels: { On: 'Open', Off: 'All' },
        api: '/api/HomeStatus/Button',
        apiUpdate: deviceApi,
        editable: false
      },
      Messages: {
        title: 'Security Messages',
        columns: columnsMessage,
        ShowOnlyActive: false,
        componentOnOffLabels: { On: 'Open', Off: 'All' },
        api: '/api/HomeStatus/Message',
        apiUpdate: deviceApi,
        editable: true
      },
      NetworkDevices: {
        title: 'Network Devices',
        columns: columnsMDNS,
        ShowOnlyActive: false,
        componentOnOffLabels: { On: 'Open', Off: 'All' },
        api: '/api/HomeStatus/MDNS',
        apiUpdate: deviceApi,
        editable: true
      }
    },
    DashDevices: {
      DashCards: {
        api: DashCardsApi
      },
      Zones: {
        ShowOnlyActive: true,
        componentOnOffLabels: { On: 'Open', Off: 'All' },
        title: 'Security Zones',
        columns: [
          columnsZones.find(x => x.title === 'Icon'),
          columnsZones.find(x => x.title === 'Name'),
          columnsZones.find(x => x.title === 'Last Updated'),
          columnsZones.find(x => x.title === 'StatusNum')
        ],
        api: '/api/HomeStatus/Zone',
        apiUpdate: deviceApi,
        editable: false
      },
      Areas: {
        ShowOnlyActive: true,
        componentOnOffLabels: { On: 'Open', Off: 'All' },
        title: 'Security Areas',
        columns: columnsAreas,
        api: '/api/HomeStatus/Area',
        apiUpdate: deviceApi,
        editable: false
      },
      Lights: {
        title: 'Lights',
        ShowOnlyActive: true,
        componentOnOffLabels: { On: 'On', Off: 'All' },
        columns: [
          columnsUnits.find(x => x.title === 'Icon'),
          columnsUnits.find(x => x.title === 'Name'),
          columnsUnits.find(x => x.title === 'Last Updated'),
          columnsUnits.find(x => x.title === 'StatusNum'),
          columnsUnits.find(x => x.field === 'Automated')
        ],
        api: '/api/HomeStatus/Lights',
        apiUpdate: deviceApi,
        editable: false,
        filter: { Automated: false }
      },
      Sensors: {
        title: 'Sensors',
        columns: columnsSensors,
        ShowOnlyActive: false,
        componentOnOffLabels: { On: 'Open', Off: 'All' },
        api: '/api/HomeStatus/Auxiliary_Sensor',
        apiUpdate: deviceApi,
        editable: false
      }
    },

    DashUser: {
      UserList: {
        title: 'Users',
        columns: usercolumns,
        ShowOnlyActive: false,
        componentOnOffLabels: { On: 'Open', Off: 'All' },
        api: userApi,
        editable: true,
        apiUpdate: pluralize.singular(userApi),
        filter: {}
      },
      Logins: {
        title: 'Logins',
        columns: loginscolumns,
        ShowOnlyActive: false,
        componentOnOffLabels: { On: 'Open', Off: 'All' },
        api: loginApi,
        editable: false,
        filter: {
          date: moment().format('YYYY-MM-DD')
        }
      }
    },
    Main: {
      SecurityEvents: {
        title: 'Security Events',
        columns: securitycolumns,
        ShowOnlyActive: true,
        componentOnOffLabels: { On: 'Open', Off: 'All' },
        api: securityApi,
        editable: false,
        filter: {
          date: moment().format('YYYY-MM-DD'),
          count: 1000,
          TypeGroup: ['Security'],
          EventType: null,
          Type: [],
          Number: [],
          Name: [],
          Status: []
        }
      },
      LightEvents: {
        title: 'Light Events',
        columns: securitycolumns,
        ShowOnlyActive: true,
        componentOnOffLabels: { On: 'On', Off: 'All' },
        api: securityApi,
        editable: false,
        filter: {
          date: moment().format('YYYY-MM-DD'),
          count: 1000,
          TypeGroup: ['Lights'],
          EventType: null,
          Type: [],
          Number: [],
          Name: [],
          Status: []
        }
      },
      MotionEvents: {
        title: 'Motion Events',
        columns: securitycolumns,
        ShowOnlyActive: true,
        componentOnOffLabels: { On: 'Motion', Off: 'All' },
        api: '/api/Security',
        editable: false,
        filter: {
          date: moment().format('YYYY-MM-DD'),
          count: 1000,
          TypeGroup: ['Motion'],
          EventType: null,
          Type: [],
          Number: [],
          Name: [],
          Status: []
        }
      }
    }
  }
};

export default config;
export { formatDate };
