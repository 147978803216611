import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
//import config from 'config';
import LogView from 'components/LogView';
import {
  Dashboard,
  //ProductList as ProductListView,
  UserList as UserListView,
  SecurityEvents as SecurityEventsView,
  //Typography as TypographyView,
  Icons as IconsView,
  //Account as AccountView,
  Settings as SettingsView,
  //SignUp as SignUpView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  DeviceList as DeviceListView,
  SingleTableView,
  AutomationList, 
  RoomList
} from './views';

const Routes = props => {
  console.log(props);


  return (
    <Switch>
      {/* <Redirect
        exact
        from="/"
        to="/dashboard"
      /> */}
      <RouteWithLayout
        component={Dashboard}
        dashdevices={props.config.RemoteDataTables.DashDevices}
        exact
        layout={MainLayout}
        path="/"
      />
      <RouteWithLayout
        component={Dashboard}
        dashdevices={props.config.RemoteDataTables.DashDevices}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={AutomationList}
        config={props.config}
        exact
        layout={MainLayout}
        path="/automationlist"
      />
      <RouteWithLayout
        component={DeviceListView}
        exact
        layout={MainLayout}
        path="/devicelist"
      />
      <RouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        path="/users"
      />
      <RouteWithLayout
        component={SecurityEventsView}
        exact
        layout={MainLayout}
        path="/securityevents"
      />
       <RouteWithLayout
        component={RoomList}
        query={props.config.RemoteDataTables.DeviceList.Rooms}
        options={{ paging: false, actionsColumnIndex: -1 }}
        exact
        layout={MainLayout}
        path="/device.rooms"
      />
      <RouteWithLayout
        component={SingleTableView}
        query={props.config.RemoteDataTables.DeviceList.Thermostats}
        options={{ paging: false, actionsColumnIndex: -1 }}
        exact
        layout={MainLayout}
        path="/device.thermostats"
      />
      <RouteWithLayout
        component={SingleTableView}
        query={props.config.RemoteDataTables.DeviceList.Areas}
        options={{ paging: false, actionsColumnIndex: -1 }}
        exact
        layout={MainLayout}
        path="/device.areas"
      />

      <RouteWithLayout
        component={SingleTableView}
        query={props.config.RemoteDataTables.DeviceList.Zones}
        options={{ paging: false, actionsColumnIndex: -1 }}
        exact
        layout={MainLayout}
        path="/device.zones"
      />
      <RouteWithLayout
        component={SingleTableView}
        query={props.config.RemoteDataTables.DeviceList.SewerPump}
        options={{ paging: false, actionsColumnIndex: -1 }}
        exact
        layout={MainLayout}
        path="/device.SewerPump"
      />
      <RouteWithLayout
        component={SingleTableView}
        query={props.config.RemoteDataTables.DeviceList.Lights}
        options={{ paging: false, actionsColumnIndex: -1 }}
        exact
        layout={MainLayout}
        path="/device.lights"
      />
      <RouteWithLayout
        component={SingleTableView}
        query={props.config.RemoteDataTables.DeviceList.Flags}
        options={{ paging: false, actionsColumnIndex: -1 }}
        exact
        layout={MainLayout}
        path="/device.flags"
      />
      <RouteWithLayout
        component={SingleTableView}
        query={props.config.RemoteDataTables.DeviceList.Outputs}
        options={{ paging: false, actionsColumnIndex: -1 }}
        exact
        layout={MainLayout}
        path="/device.outputs"
      />
      <RouteWithLayout
        component={SingleTableView}
        query={props.config.RemoteDataTables.DeviceList.Cameras}
        options={{ paging: false, actionsColumnIndex: -1 }}
        exact
        layout={MainLayout}
        path="/device.cameras"
      />
      <RouteWithLayout
        component={SingleTableView}
        query={props.config.RemoteDataTables.DeviceList.Sensors}
        options={{ paging: false, actionsColumnIndex: -1 }}
        exact
        layout={MainLayout}
        path="/device.sensors"
      />
      <RouteWithLayout
        component={SingleTableView}
        query={props.config.RemoteDataTables.DeviceList.NetworkDevices}
        options={{ paging: false, actionsColumnIndex: -1 }}
        exact
        layout={MainLayout}
        path="/device.networkdevices"
      />
      <RouteWithLayout
        component={SingleTableView}
        query={props.config.RemoteDataTables.DeviceList.Buttons}
        options={{ paging: false, actionsColumnIndex: -1 }}
        exact
        layout={MainLayout}
        path="/device.buttons"
      />
      <RouteWithLayout
        component={SingleTableView}
        query={props.config.RemoteDataTables.DeviceList.Messages}
        options={{ paging: false, actionsColumnIndex: -1 }}
        exact
        layout={MainLayout}
        path="/device.messages"
      />
      
      <RouteWithLayout
        component={LogView}
        exact
        layout={MainLayout}
        path="/logs/:LogId"
      />

      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />

      {/* <RouteWithLayout
        component={ProductListView}
        exact
        layout={MainLayout}
        path="/products"
      /> */}
      <RouteWithLayout
        component={IconsView}
        exact
        layout={MainLayout}
        path="/icons"
      />
      {/* <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
      /> */}
      
      {/* <RouteWithLayout
        component={TypographyView}
        exact
        layout={MainLayout}
        path="/typography"
      /> */}
      {/* <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      /> */}
      <Route
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
