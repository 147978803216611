import React from 'react';
import Axios from 'axios';
//import config from 'config';

export function getToken() {
    return window.localStorage['jwtToken'];
}

export function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}

// function LogIn() {
//   console.log("Login() " + config.loginUrl)
//   window.location = config.loginUrl;
// };

export function isAuthed(jwtToken) {
  if (jwtToken) {
      var params = parseJwt(jwtToken);
      //return Math.round(new Date().getTime() / 1000) <= params.exp;
      var isgood = Math.round(new Date().getTime() / 1000) <= params.exp;
      Axios.defaults.headers.common['Authorization'] = jwtToken;
      return isgood;
  } else {
      Axios.defaults.headers.common['Authorization'] = "";
      return false;
  }
};

// const defaultContext = {
//     name: 'none',
//     email: 'none',
//     role: '',
//     image: null,
//     token: null,
//     // get getToken() {
//     //   if (!this.token) this.token = window.localStorage['jwtToken'];
//     //   return this.token;
//     // },
// };

export default React.createContext();
//export {LogIn}
