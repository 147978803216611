import React, { Component } from 'react';
//import { makeStyles } from '@material-ui/styles';
import MaterialTable, { MTableToolbar, MTableFilterRow } from '@material-table/core';
// Replace material-table with @material-table/core
import {
    Typography,
    List,
    ListItem,
    Select,
    MenuItem,
    Input,
    Checkbox,
    ListItemText
} from '@material-ui/core';
import Axios from 'axios';
import moment from 'moment';

//port RoomListEdit from 'components/RoomListEdit';
//import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import FullScreenDialog from '../components/FullScreenDialog';

import config from 'config';

const classes = theme => ({
    root: {
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2)
    }
});

// const formatDate = (rowData, item) => {
//   return moment(rowData[item]).local().format('llll');
// }



class RoomList extends Component {
    tableRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            data: [],
            listRooms: [],   // The list of room Names, used for edit select
            selectedRooms: [],
            showError: null,
            dialogOpen: false,   // is the Add Room Dialog Open or closed
            DeviceList: [],  // This is the list of current devices from server, in form [{label: ??, value: ??}]
            dialogTitle: "",
            dialogData: {},
            dialogViewType: "raw"  // determines how to display data in dialog: raw = display raw string, "table"= get history of row data and 
            // display table.  "chart" = get history of row data and display on chart
        };
    }
    /// TODO: Figure out how to send Data list to FullScren Dialog, send a rowdata now, but need to send list of 
    // Device IDs

    columnsRooms = [
        { field: 'Name', title: 'Name', editable: 'always' },
        { field: 'Type', title: 'Type', editable: 'never', hidden: true, initialEditValue: 'Room' },
        { field: 'DeviceId', title: 'DeviceId', editable: 'never', hidden: true },
        { field: 'Description', title: 'Description', editable: 'always' },
        {
            field: 'Devices', title: 'Devices', initialEditValue: [], editable: 'always',
            render: rowData => (
                <List dense={true}>
                    {rowData.Devices.map(item => {
                        let name = this.state.DeviceList.find(dev => dev.value === item);
                        if (typeof name === "object")
                            name = name.name;
                        else
                            name = "unknown";
                        return (<ListItem key={item}> {name}</ListItem>);
                    })}
                </List>
            ),
            // The Room Object has a Row item Devices, which is an array of DeviceIds
            // The edit component takes the list of all devices, in the form {name: ??, value: <DeviceID>}
            // and shows a list of all the devices and highlights the ones selected, then allows more to be selected.
            // It then updates the array of device ids with any changes.
            editComponent: props => {
                return (
                    // <ReactMultiSelectCheckboxes options={this.state.DeviceList}>

                    // </ReactMultiSelectCheckboxes>
                    <Select
                        labelId="mutiple-checkbox-label"
                        id="mutiple-checkbox"
                        multiple
                        value={props.value}
                        onChange={e => props.onChange(e.target.value)}
                        input={<Input />}
                        renderValue={(selected) =>
                            <List dense={true}>
                                {selected.map(item => {
                                    let name = this.state.DeviceList.find(dev => dev.value === item);
                                    if (typeof name === "object")
                                        name = name.name;
                                    else
                                        name = "unknown";
                                    return (<ListItem key={item}> {name}</ListItem>);
                                })}
                            </List>
                        }
                    >
                        {this.state.DeviceList.map((device) => (
                            <MenuItem key={device.value} value={device.value}>
                                <Checkbox checked={props.value.findIndex(dev => dev === device.value) !== -1} />
                                <ListItemText primary={device.name} />
                            </MenuItem>
                        ))}
                    </Select>
                );
            }
        },
        {
            title: 'Show in Dashboard',
            field: 'ShowInDashboard',
            type: 'numeric'
        }
        // {
        //     field: 'Rooms',
        //     title: 'Rooms',
        //     editable: 'always',
        //     //emptyValue: 'None',
        //     render: rowData => (
        //         <List dense={true}>
        //             {rowData.Rooms.map(item => (
        //                 <ListItem key={item}>- {item}</ListItem>
        //             ))}
        //         </List>
        //     ),
        //     //editComponent: props => (<ReactMultiSelectCheckboxes options= {this.state.listRooms}   ></ReactMultiSelectCheckboxes>)
        //     editComponent: props => (

        //         <Select multiple value={props.value} onChange={(e) => {
        //             props.onChange(e.target.value);
        //             //props.value = e.target.value;
        //             this.handleChange(e);
        //         }}>
        //             {// Filter out this Rows name (can't add room to itself)
        //                 this.state.listRooms.filter(n => n !== props.rowData.Name).map(x => (
        //                     <MenuItem key={x} value={x}>
        //                         {x}
        //                     </MenuItem>
        //                 ))}
        //         </Select>)
        // },
    ];

    Rooms = {
        ShowOnlyActive: false,
        componentOnOffLabels: { On: 'Active', Off: 'All' },
        title: 'Rooms',
        api: '/api/HomeStatus/Room',
        apiUpdate: config.deviceApi,
        editable: true
    }

    handleChange = (event) => {
        this.setState({ selectedRooms: event.target.value });
    };

    handleNewRoom = (event) => {
        this.setState({ dialogOpen: true });
    }


    // This ids the Parents of each child
    // Child DeviceId != null
    // Parent: DeviceId == null
    // TimeOn Date of Parent = TimeOn  Date of Child
    // TimeOn of Parent is the SunRise of the date of Parent
    parentChildData = (row, rows) => {
        //return (
        let found = rows.find(parent => {
            //console.log(`Find ${parent.DeviceId} ?? ${row.DeviceId} ::  ${moment(parent.TimeOn).local().format("YYYY-MM-DD")} ?? ${moment(row.TimeOn).local().format("YYYY-MM-DD")}`)
            let v = typeof parent.DeviceId === 'undefined' &&
                typeof row.DeviceId !== 'undefined' &&
                moment(parent.TimeOn).local().format("YYYY-MM-DD") === moment(row.TimeOn).local().format("YYYY-MM-DD");
            //console.log(`V = ${v}`)  
            return v;
        })
        //)
        //console.log(found)
        return found;
    }

    components = {
        FilterRow: props => <MTableFilterRow {...props} />,
        Toolbar: props => (
            <div>
                <div>
                    <MTableToolbar {...props} />
                    {this.state.showError == null ? ( // if there is an Error, show Error in ToolBar, otherwise show toolbar
                        <div style={{ padding: '10px', display: 'flex' }}>
                            {/* <Switch hidden= offLabel={props.title + ": All"} onLabel="On" onChange= {this.handleShowOnlyActive} /> */}
                            {/* {this.props.query.ShowOnlyActive ? (
                <Switch
                  key={'ShowOnlyActive' + props.title.replace(/ /g, '_')}
                  id={'ShowOnlyActive' + props.title.replace(/ /g, '_')}
                  offLabel={'Show Only Active'}
                  // offLabel={
                  //   'Show ' +
                  //   props.title +
                  //   ': ' +
                  //   this.props.query.componentOnOffLabels.Off
                  // }
                  // onLabel=""{this.props.query.componentOnOffLabels.On}
                  onLabel=""
                  onChange={this.handleShowOnlyActive}
                  style={{ marginRight: 10 }}
                  checked={this.state.showShowOnlyActive}
                />
              ) : (
                <div> </div>
              )} */}
                            {/* {this.props.query.title === 'Lights' ? (
                <Switch
                  id={'ShowAutomatedLights' + props.title}
                  offLabel={'Show Automated Devices'}
                  // offLabel={
                  //   'Show ' +
                  //   props.title +
                  //   ': ' +
                  //   this.props.query.componentOnOffLabels.Off
                  // }
                  // onLabel=""{this.props.query.componentOnOffLabels.On}
                  onLabel=""
                  onChange={this.handleFilterByShowAutomatedChange}
                  style={{ marginRight: 10 }}
                  // If True, show Automated Devices/Lights in List
                  checked={
                    this.props.query.filter && this.props.query.filter.Automated
                  }
                />
              ) : (
                <div> </div>
              )} */}
                            {/* <Switch
                id={'ShowSearch' + props.title}
                offLabel={'Show Search:'}
                onLabel=""
                onChange={this.handleShow}
                style={{ marginRight: 10 }}
              />{' '} */}
                            {/* <Switch
                id={'ShowFilter' + props.title}
                offLabel={'Show Filter:'}
                onLabel=""
                onChange={this.handleShow}
                style={{ marginRight: 10 }}
              />{' '} */}
                        </div>
                    ) : (
                            <div style={{ padding: '10px', align: 'center' }}>
                                <Typography variant="h3" align="center" color="error">
                                    {' '}
                                    {this.state.showError}
                                </Typography>
                            </div>
                        )}
                </div>
            </div>
        )
    };

    editable = {
        onRowAdd: createdData => {
            //alert("Add Row");
            let t = this;
            let newData = {};
            newData = Object.assign(newData, createdData);
            newData.Type = 'Room';
            newData.DeviceId = newData.Type + newData.Name;
            return new Promise((resolve, reject) => {
                Axios.post(this.Rooms.apiUpdate + newData.DeviceId, newData)
                    .then(function (response) {
                        if (response.data.success) {
                            t.LoadData();
                            resolve();
                        } else
                            throw new Error(response.data.message);
                    }).catch(function (error) {
                        t.setState({ showError: `Failed to Add Automation for - ${error.response.data.error}` });
                        reject();
                    });
            });
        },

        onRowUpdate: ((newData, oldData) => {

            //let DBname = pluralize.singular(t.props.query.title);
            //let deviceId = newData.id || newData._id;

            // If the Room Name is changed, confirm they want to rename the room in all 
            // the other rooms as well
            let t = this;
            return new Promise((resolve, reject) => {
                Axios.put(this.Rooms.apiUpdate + newData._id, newData)
                    .then(function (response) {
                        if (response.data.success) {
                            //alert("Updating "+ DBname+ " Succeeded "  );
                            t.LoadData();
                            resolve();
                        } else
                            throw new Error(response.data.message);
                    })
                    .catch(function (error) {
                        t.setState({ showError: `Failed to Update Automation for ${oldData.Name}  - ${error.response.data.error}` });
                        reject();
                    });
            })

        }),

        onRowDelete: rowData => {
            let t = this;
            return new Promise((resolve, reject) => {
                Axios.delete(this.Rooms.apiUpdate + rowData._id, {
                    data: [rowData]   //send Array of items to delete
                })
                    .then(function (response) {
                        if (response.data.success) {
                            t.LoadData();
                            resolve();
                        } else
                            throw new Error(response.data.message);
                    }).catch(function (error) {
                        t.setState({ showError: `Failed to Delete Automation for ${rowData.Description || rowData.DeviceId}  - ${error.response.data.error}` });
                        reject();
                    });
            });
        }
    };

    actionShowRawData = {
        icon: 'text_snippet',
        tooltip: 'Show Data',
        hidden: false,
        onClick: (event, rowData) => {
            this.setState({ dialogData: JSON.stringify(rowData, null, 3) });
            this.setState({ dialogOpen: true });
            this.setState({ dialogTitle: true });
            this.setState({ dialogViewType: "raw" })
        }
    }

    actionShowHistoryTable = {
        icon: 'table_rows',
        tooltip: 'Show History',
        hidden: false,
        onClick: (event, rowData) => {
            this.setState({ dialogData: JSON.stringify(rowData, null, 3) });
            this.setState({ dialogOpen: true });
            this.setState({ dialogTitle: true });
            this.setState({ dialogViewType: "table" })
        }
    }
    actionShowHistoryChart = {
        icon: 'insert_chart',
        tooltip: 'Show History Chart',
        hidden: false,
        onClick: (event, rowData) => {
            this.setState({ dialogData: JSON.stringify(rowData, null, 3) });
            this.setState({ dialogOpen: true });
            this.setState({ dialogTitle: true });
            this.setState({ dialogViewType: "chart" })
        }
    }


    // actions = [
    //   {
    //     icon: 'library_add',
    //     tooltip: 'Duplicate Automation Item',
    //     onClick: (event, rowData) => {
    //       return new Promise((resolve, reject) => {
    //         let t = this;
    //         let dataArray = [rowData];
    //         if (typeof rowData.DeviceId === 'undefined') {   // This is a Date row, not data Row
    //           let rows = this.tableRef.current.dataManager.data.filter(row => typeof row.DeviceId !== 'undefined' && moment(rowData.TimeOn).local().isSame(row.TimeOn, 'day'))
    //           /// TODO: Change this to server side copy, to can change for Sunrise/Sunset changes
    //           rows.forEach(row => {
    //             row.TimeOn = moment(row.TimeOn).add(1, 'days');
    //             row.TimeOff = moment(row.TimeOff).add(1, 'days');
    //           })
    //           dataArray = rows;
    //         }
    //           // Add Copy of current Item to List
    //         Axios.post(this.state.api, dataArray)
    //           .then(function (response) {
    //             if (response.data.success) {
    //               t.LoadData();
    //               resolve();
    //             } else
    //               throw new Error(response.data.message);
    //           }).catch(function (error) {
    //             t.setState({ showError: `Failed to Copy Automation Item - ${error.response.data.error}` });
    //             reject();
    //           });
    //       });
    //     }
    //   }
    // ]


    componentDidMount() {

        this.LoadData();
    }

    LoadData() {
        this.setState({ isLoading: true });

        Axios.get(this.Rooms.api, { params: {} }).then(result => {
            this.setState({ data: result.data.data });
            this.setState({ listRooms: result.data.data.map(x => x.Name) });  // get list of just room Names
            this.setState({ isLoading: false });
            this.setState({ showError: null });
        }).catch(error => {
            //this.setState({ data: ["Error: - "+ error.message], isLoading: false });
            console.log(`RoomList  Error - ${error.message}`);
            this.setState({ isLoading: false });
            this.setState({ showError: `Get Room List ${error.message}` });
        });

        if (this.state.DeviceList.length === 0) {
            //alert(config.deviceApi);
            Axios.get(this.Rooms.apiUpdate + "All", { params: {} }).then(devices => {
                let list = devices.data.data.map(x => { return { name: `${x.Name} (${x.Type})`, value: x.DeviceId } });
                this.setState({ DeviceList: list });
            }).catch(err => {
                this.setState({ showError: `Failed to Get List of Devices - ${err}` });
            })
        };

    }

    render() {
        return (
            <div className={classes.root}>
                <div className={classes.content}>
                    {/* <RemoteDataTable key="SecurityEvents" query={this.props.config.RemoteDataTables.DashSecurity} options={{ filtering: true, pageSize: 10, pageSizeOptions: [10, 20, 30] }} /> */}
                    <FullScreenDialog dialogOpen={this.state.dialogOpen} title={this.state.dialogTitle} data={this.state.dialogData} dialogViewType={this.state.dialogViewType} Close={() => { this.setState({ dialogOpen: false }) }} />
                    <MaterialTable
                        tableRef={this.tableRef}
                        title='Room List'
                        size="small"
                        style={{ valign: 'top', height: '100%' }}
                        options={{ paging: false, actionsColumnIndex: -1 }}
                        actions={[this.actionShowRawData, this.actionShowHistoryTable, this.actionShowHistoryChart]}
                        columns={this.columnsRooms}
                        editable={this.editable}
                        data={this.state.data}
                        //onRowClick={this.RowClick}
                        components={this.components}
                        parentChildData={this.parentChildData}
                        isLoading={this.state.isLoading}
                        stickyHeader>
                    </MaterialTable>
                </div>
            </div>
        );
    }
};

export default RoomList;
